/**
 * 对话中出现的文件条
 */

import { Progress, Tooltip } from 'antd';
import CustomIcon from '../CustomIcon';
import GoogleIcons from '../CustomIcon/GoogleIcons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { kvMap } from '@/type';
import './index.less';
import { FileType, getFileType } from '@/common/helpers/fileHelper';

interface FileInfo {
  file_name: string;
  file_page_count?: number;
  file_type: string;
  err_msg?: string;
  file_desc?: string;
  gdrive_doc_url?: string;
}

interface FileProps extends Record<string, any> {
  silent?: boolean; //静默状态，仅做展示
  content: FileInfo;
  showProcess?: boolean;
  percent?: number;
  errorMsg?: string;
  isReady?: boolean;
  forceBreak?: boolean;
  onRedo?: () => void;
  onRest?: () => void;
}

const File: React.FC<FileProps> = ({
  content,
  showProcess = false,
  percent,
  errorMsg,
  isReady = false,
  forceBreak,
  silent = false,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    file_type: fileType,
    file_name: fileName,
    file_page_count: filePageCount = 0,
    file_desc: fileDesc,
    gdrive_doc_url: gDriveDocUrl,
    err_msg,
  } = content;
  const errMsg = errorMsg || err_msg;
  const renderSubtitle = () => {
    if (gDriveDocUrl && !errMsg) {
      const gDriveFileTypes: kvMap<string> = {
        ppt: t('common.googleSlides'),
        pptx: t('common.googleSlides'),
        doc: t('common.googleDocs'),
        docx: t('common.googleDocs'),
        xlsx: t('common.googleSheets'),
        xls: t('common.googleSheets'),
      };
      const text = gDriveFileTypes[fileType] || t('common.googleDrive');
      return <span className="page-count">{text}</span>;
    }
    if (fileType === FileType.writing) return <span className="page-count">Writing</span>;
    return (
      <>
        {errMsg ? (
          <div className="error-msg">
            <Tooltip title={errMsg}>
              <span className="error-msg-text ellipsis">{errMsg}</span>
            </Tooltip>
            {props.onRedo && !silent && (
              <CustomIcon className="file-redo" type="redo" onClick={props?.onRedo} />
            )}
          </div>
        ) : (
          <span className="page-count">
            {filePageCount} {filePageCount > 1 ? t('common.pages') : t('common.page')}
          </span>
        )}
      </>
    );
  };

  const renderIcon = () => {
    if (gDriveDocUrl) {
      return <GoogleIcons type={fileType} />;
    }
    return (
      <CustomIcon className="file-icon" type={`${getFileType(fileType, FileType.doc)}FileBig`} />
    );
  };

  const handleClickCard = () => {
    if (gDriveDocUrl) {
      window.open(gDriveDocUrl, '_blank');
    }
  };

  const nodes = (
    <div
      onClick={handleClickCard}
      className={classNames({
        'file-content': true,
        silent: silent,
        error: errorMsg || errMsg,
      })}
      {...props}
    >
      {props?.onRest && !silent && (
        <CustomIcon className="file-delete" type="close" onClick={props?.onRest} />
      )}
      <div className="file-content-main">
        {renderIcon()}
        <div className="file-title-wrapper">
          <h1 className="ellipsis" title={fileName}>
            {fileName}
          </h1>
          {isReady || forceBreak || fileType === FileType.writing ? (
            renderSubtitle()
          ) : (
            <div className="loading-progress">
              <span>{fileDesc ?? t('components.message.parsing')}</span>
            </div>
          )}
        </div>
        {showProcess && (
          <Progress
            className="file-progress"
            strokeLinecap="butt"
            size="small"
            strokeColor="#3C38FF"
            trailColor="#E4E6EB"
            showInfo={false}
            percent={percent}
          />
        )}
      </div>
    </div>
  );
  if (gDriveDocUrl && !errMsg) {
    return (
      <Tooltip
        overlayClassName="file-tooltip"
        placement="top"
        title={
          <a className="file-tooltip-container" target="_blank" href={gDriveDocUrl}>
            <p className="file-tooltip-text">{gDriveDocUrl}</p>
            <CustomIcon type="FileLink" />
          </a>
        }
      >
        {nodes}
      </Tooltip>
    );
  }
  return nodes;
};

export default File;
