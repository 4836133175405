import React from 'react';
import { Popover, ConfigProvider, Dropdown } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import ImageUpload, { FinishUploadParams } from '@/components/ImageUpload';
import { GalleryItem, getAiGenItems, getGalleryItems } from '../../components/GalleryType';
import useGASendEvent from '@/hooks/useGASendEvent';

import './index.less';
import { useImageToolContent } from './useImageTooBar';
import { useTranslation } from 'react-i18next';

interface Props {
  content?: React.ReactNode;
  onClick: (params: React.MouseEvent) => void;
  children?: React.ReactNode;
  open?: boolean;
  onUploadStart?: () => void;
  onUploadFinished?: (params: FinishUploadParams) => void;
  onGalleryChange: (params: GalleryItem) => void;
  onAiGenChange: (params: GalleryItem) => void;
  disabled?: boolean;
  isImageClear?: boolean;
}

export interface ContentProps extends Props {
  onUploadStart?: () => void;
  onUploadFinished?: (params: FinishUploadParams) => void;
}

const Content = ({
  onClick,
  onUploadFinished,
  onUploadStart,
  onGalleryChange,
  isImageClear,
  onAiGenChange,
}: ContentProps) => {
  const {
    imageUploadRef,
    isUploadImage,
    isDropdownOpen,
    galleryOpenhandler,
    handleItemClick,
    startImageUploadHandler,
    beforeUploadHandler,
    finishImageUploadHandler,
    isAiDropdownOpen,
    aiGenOpenHandler,
  } = useImageToolContent({
    onClick,
    onUploadFinished,
    onUploadStart,
    onGalleryChange,
    isImageClear,
    onAiGenChange,
  });

  const { sendEvent } = useGASendEvent();
  const { t } = useTranslation();

  const items = getGalleryItems(onGalleryChange, sendEvent);
  const aiGenItems = getAiGenItems(onAiGenChange, sendEvent);

  return (
    <section className="img-toolbar__wrapper">
      <div className="img-toolbar__item" onClick={(e) => handleItemClick('shuffle', e)}>
        <CustomIcon type="shuffle" tooltip={t('pages.presentation.shuffle')} />
      </div>
      <div className="img-toolbar__item">
        <ImageUpload
          ref={imageUploadRef}
          isUploadImage={isUploadImage}
          onStartUpload={startImageUploadHandler}
          onFinishUpload={finishImageUploadHandler}
          beforeUpload={beforeUploadHandler}
          type="ppt"
        >
          <CustomIcon
            type="uploadPPTImg"
            tooltip={t('pages.presentation.upload')}
            onClick={() => sendEvent('ClickUploadImageInPptToolBar')}
          />
        </ImageUpload>
      </div>
      <div className="img-toolbar__item" style={{ padding: '0 6px' }}>
        <Dropdown
          overlayClassName="img-toolbar__dropdown-container"
          menu={{ items }}
          onOpenChange={galleryOpenhandler}
          destroyPopupOnHide
        >
          <span className="img-toolbar__collection">
            <CustomIcon type="collection" />
            <span className="img-toolbar__gallery">{t('pages.presentation.gallery')}</span>
            <CustomIcon type={isDropdownOpen ? 'up' : 'down'} />
          </span>
        </Dropdown>
      </div>
      <div className="img-toolbar__item" style={{ padding: '0 6px' }}>
        <Dropdown
          overlayClassName="img-toolbar__dropdown-container"
          menu={{ items: aiGenItems }}
          onOpenChange={aiGenOpenHandler}
          destroyPopupOnHide
        >
          <span className="img-toolbar__collection">
            <CustomIcon type="enhance" />
            <span className="img-toolbar__ai-img">{t('pages.presentation.aiImage')}</span>
            <CustomIcon type={isAiDropdownOpen ? 'up' : 'down'} />
          </span>
        </Dropdown>
      </div>
      <div className="img-toolbar__item" onClick={(e) => handleItemClick('delete', e)}>
        <CustomIcon
          type={isImageClear ? 'eraserDisabled' : 'eraser'}
          tooltip={isImageClear ? t('pages.presentation.cleared') : t('pages.presentation.clear')}
        />
      </div>
    </section>
  );
};

const ImgToolbar: React.FC<Props> = ({
  onClick,
  children,
  onUploadFinished,
  onUploadStart,
  onGalleryChange,
  isImageClear,
  onAiGenChange,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          boxShadowSecondary:
            '0px 0px 8px 0px rgba(0, 0, 0, 0.2), 0px 8px 24px 0px rgba(0, 0, 0, 0.12)',
        },
      }}
    >
      <Popover
        title=""
        overlayClassName="img-toolbar__popover"
        content={
          <Content
            onClick={onClick}
            onUploadFinished={onUploadFinished}
            onUploadStart={onUploadStart}
            onGalleryChange={onGalleryChange}
            onAiGenChange={onAiGenChange}
            isImageClear={isImageClear}
          />
        }
        placement="top"
        arrow={false}
        trigger={['hover']}
        mouseLeaveDelay={0.2}
      >
        {children}
      </Popover>
    </ConfigProvider>
  );
};

export default ImgToolbar;
