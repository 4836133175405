import { useCallback, useState } from 'react';

export const useImageFocus = () => {
  const [showFocus, setShowFocus] = useState<boolean>(false);

  const focusHandler = useCallback((e: Event) => {
    const panel = document.getElementById('operation-panel-ref');
    const chatViewHeader = document.querySelector('.chat-view-header');
    const panelClose = document.querySelector('.present-panel__close');
    const imagePanelDropdown = document.querySelector('.present-panel__gallery-dropdown');
    const aiGenEnhanceDropdown = document.querySelector('.present-panel__ai-enhance');

    if (
      //@ts-ignore
      panel?.contains(e.target) ||
      //@ts-ignore
      chatViewHeader?.contains(e.target) ||
      //@ts-ignore
      panelClose?.contains(e.target) ||
      //@ts-ignore
      imagePanelDropdown?.contains(e.target) ||
      //@ts-ignore
      aiGenEnhanceDropdown?.contains(e.target)
    ) {
      return;
    } else {
      setShowFocus(false);
      // showFocus.current = false;
    }
  }, []);

  const addClickOutside = useCallback(() => {
    setTimeout(() => {
      document.addEventListener('mousedown', focusHandler);
    });
  }, []);

  const removeClickOutside = useCallback(() => {
    document.removeEventListener('mousedown', focusHandler);
  }, []);

  const setFocuesRadius = useCallback((imgId: string) => {
    const focusElement = document.getElementById(imgId);

    if (focusElement) {
      // 向上遍历父节点直到找到 .card-block-tray
      let parent = focusElement.parentElement;
      while (parent && !parent.classList.contains('card-block-tray')) {
        parent = parent.parentElement;
      }
      // 如果找到了 .card-block-tray 元素
      if (parent) {
        const parentStyle = window.getComputedStyle(parent);
        const top = parseFloat(parentStyle.top);
        const left = parseFloat(parentStyle.left);
        const right = parseFloat(parentStyle.right);
        const bottom = parseFloat(parentStyle.bottom);
        let borderTopLeftRadius = 'inherit';
        let borderTopRightRadius = 'inherit';
        let borderBottomRightRadius = 'inherit';
        let borderBottomLeftRadius = 'inherit';
        if (top === 0 && left === 0 && right === 0 && bottom === 0) {
          borderTopLeftRadius = '16px';
          borderTopRightRadius = '16px';
          borderBottomRightRadius = '16px';
          borderBottomLeftRadius = '16px';
        }

        if (top === 0 && left === 0 && right === 0 && bottom !== 0) {
          borderTopLeftRadius = '16px';
          borderTopRightRadius = '16px';
        }

        if (top === 0 && left === 0 && right !== 0 && bottom === 0) {
          borderTopLeftRadius = '16px';
          borderBottomLeftRadius = '16px';
        }

        if (top === 0 && left !== 0 && right === 0 && bottom === 0) {
          borderTopRightRadius = '16px';
          borderBottomRightRadius = '16px';
        }

        if (top !== 0 && left === 0 && right === 0 && bottom === 0) {
          borderBottomLeftRadius = '16px';
          borderBottomRightRadius = '16px';
        }

        // 设置圆角样式
        focusElement.style.borderTopLeftRadius = borderTopLeftRadius;
        focusElement.style.borderTopRightRadius = borderTopRightRadius;
        focusElement.style.borderBottomRightRadius = borderBottomRightRadius;
        focusElement.style.borderBottomLeftRadius = borderBottomLeftRadius;
      }
    }
  }, []);

  return {
    showFocus,
    setShowFocus,
    addClickOutside,
    removeClickOutside,
    setFocuesRadius,
  };
};
