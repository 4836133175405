import { Node, mergeAttributes, JSONContent } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';

export interface CardBlockOptions {
  name?: string;
  HTMLAttributes: Record<string, any>;
}

const NODE_NAME = 'cardblock';

const CardBlock = Node.create<CardBlockOptions>({
  name: NODE_NAME,

  priority: 1000,

  group: 'block',

  inline: false,

  content: 'block*',

  selectable: true,

  draggable: false,

  // defining: true,

  // atom: false,

  // isolating: true,

  addOptions() {
    return {
      name: '',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        rendered: false,
      },
      name: {
        default: '',
        rendered: false,
      },
      layoutItemId: '',
      layoutType: '',
    };
  },

  parseHTML() {
    return [{ tag: 'div', attrs: { name: this.options.name ?? '' } }];
  },

  renderHTML({ node, HTMLAttributes }) {
    const { name, layoutItemId, layoutType = 'card-block' } = node.attrs;
    const cardNodeCount = (node.content as Fragment & { content?: JSONContent })?.content?.length;

    let dynamicLayoutClassName = '';
    if (cardNodeCount % 2 === 0 && cardNodeCount <= 4) {
      dynamicLayoutClassName = 'card-block-multiple-of-two';
    } else if (cardNodeCount % 3 === 0 || cardNodeCount > 4) {
      dynamicLayoutClassName = 'card-block-multiple-of-three';
    }

    return name
      ? [
          'div',
          mergeAttributes(
            this.options.HTMLAttributes,
            HTMLAttributes,
            {
              class: `card-block card-block-${name} ${dynamicLayoutClassName}`,
            },
            { 'data-layout-item-id': layoutItemId },
            { 'data-layout-type': layoutType },
          ),
          0,
        ]
      : ['span'];
  },
});

export default CardBlock;
