import SearchIcon from '@/assets/search.gif';
import { MessageItem } from '@/type';
import Markdown from '../Markdown';

interface Props {
  text: string;
  message: MessageItem;
}

const SearchTip = (props: Props) => {
  const { text, message } = props;

  return (
    <div className="ai-message-tips">
      {message?.tip?.includes('Searching') ? (
        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <img width={16} height={16} src={SearchIcon} />
          <span>{text}</span>
        </div>
      ) : (
        <div className="ai-message-tips__cursor">
          <Markdown content={message?.tip} />
        </div>
      )}
    </div>
  );
};

export default SearchTip;
