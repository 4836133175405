export const HEADING_SIZE = 200; //heading字符串长度限制
export const CARD_BLOCK_SIZE = 15000; //cardblock字符串长度限制

/* PPT 相关的操作类型定义 */
export enum operationType {
  openGalleryPanel = 'openGalleryPanel',
  imgUploadFinished = 'imgUploadFinished',
  imgChanged = 'imgChanged',
  imgOnLoaded = 'imgOnLoaded',
  openAiGenPanel = 'openAiGenPanel',
}
/* 定义存储桶的类型 */
export enum galleryType {
  history = 1,
  popai,
  theme,
  import,
}
/**
 * 使用第三方的 click out side 发现无法实现预期逻辑，怀疑和 tiptap 有关系
 * 得再做一些调研，目前先用这种方式实现功能
 */
export const IMG_PANEL_FOCUS_TARGET = [
  'present-imgs__img-show',
  'present-imgs',
  'chat-dropdown-menu-item',
  'present-panel__gallery-title',
  'img-toolbar__dropdown-text',
  'chat-drawer-body present-panel__drawer',
  'present-panel__header',
];
// ai生成图片的类型
export enum aiGenImgType {
  aiSearch = 1,
  aiGeneration,
}
