import CustomIcon from '@/components/CustomIcon';
import { MenuProps } from 'antd';
import { galleryType, aiGenImgType } from '@/components/PresentationView/config';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import i18n from '@/i18n';

export interface GalleryItem {
  key: number | string;
  title: string;
  icon: string;
  eventName: string;
  type?: string;
}

interface SettingItem {
  code: string;
  name: string;
}
export interface EnhanceSettings {
  pptSearchEnhanceDesc: SettingItem[];
  pptSearchEnhanceStyle: SettingItem[];
}

export const getGalleryTypes = () => {
  const galleryTypes = {
    [galleryType.history]: {
      key: galleryType.history,
      title: i18n.t('pages.presentation.projectHistory'),
      icon: 'history',
      eventName: 'ClickProjectHistoryInPptToolBar',
    },
    [galleryType.popai]: {
      key: galleryType.popai,
      title: i18n.t('pages.presentation.popAiGallery'),
      icon: 'folderOpen',
      eventName: 'ClickPopAiGalleryInPptToolBar',
    },
    [galleryType.theme]: {
      key: galleryType.theme,
      title: i18n.t('pages.presentation.themeGallery'),
      icon: 'themeGallery',
      eventName: 'ClickThemeGalleryInPptToolBar',
    },
    [galleryType.import]: {
      key: galleryType.import,
      title: i18n.t('pages.presentation.importCollection'),
      icon: 'importCollection',
      eventName: 'ClickImportCollectionInPptToolBar',
    },
  };
  return galleryTypes;
};
export const getGalleryType = (key: galleryType) => {
  return getGalleryTypes()[key];
};

export const getImgGenTypes = (): {
  [key in aiGenImgType.aiSearch]: GalleryItem;
} => {
  return {
    [aiGenImgType.aiSearch]: {
      key: aiGenImgType.aiSearch,
      title: i18n.t('pages.presentation.aiSearch'),
      icon: 'aiSearch',
      eventName: 'ClickAiSearchInPptToolBar',
    },
    // [aiGenImgType.aiGeneration]: {
    //   key: aiGenImgType.aiGeneration,
    //   title: 'AI Generation',
    //   icon: 'aiGeneration',
    //   eventName: 'ClickAiGenInPptToolBar',
    // },
  };
};

export const AI_ENHANCE_PARAMS: {
  [key: string]: {
    icon: string;
    eventName: string;
  };
} = {
  abstract: {
    icon: 'atom',
    eventName: 'SwitchAiSearchStyle',
  },
  detail: {
    icon: 'imageDetail',
    eventName: 'SwitchAiSearchStyle',
  },
  action: {
    icon: 'shareAction',
    eventName: 'SwitchAiSearchStyle',
  },
  object: {
    icon: 'template',
    eventName: 'SwitchAiSearchStyle',
  },
  scenario: {
    icon: 'location',
    eventName: 'SwitchAiSearchStyle',
  },
  photography: {
    icon: 'camera',
    eventName: 'EnhanceAiSearchInPptPanel',
  },
  '3d': {
    icon: 'cube',
    eventName: 'EnhanceAiSearchInPptPanel',
  },
  animation: {
    icon: 'film',
    eventName: 'EnhanceAiSearchInPptPanel',
  },
};

export const getGalleryItems = (
  onGalleryChange: (params: GalleryItem) => void,
  sendEvent: { (name: string, params?: any): Promise<void>; (arg0: string): void },
  isShowImportCollection?: boolean,
) => {
  const items: MenuProps['items'] = [];
  const galleryTypes = getGalleryTypes();
  const galleryKeysArr = Object.keys(galleryTypes);
  for (let i = 0; i < galleryKeysArr.length; i++) {
    const key = galleryKeysArr[i];
    if (key === galleryType.import.toString() && !isShowImportCollection) {
      continue;
    }
    items.push({
      key: Number(key),
      label: (
        <span className="img-toolbar__dropdown">
          <CustomIcon type={galleryTypes[Number(key) as galleryType].icon} />
          <span className="img-toolbar__dropdown-text">
            {galleryTypes[Number(key) as galleryType].title}
          </span>
        </span>
      ),
      onClick: ({ key }) => {
        onGalleryChange && onGalleryChange(galleryTypes[Number(key) as galleryType]);
        sendEvent(galleryTypes[Number(key) as galleryType].eventName);
      },
    });
  }
  // Object.keys(galleryTypes).forEach((key) => {
  //   items.push({
  //     key: Number(key),
  //     label: (
  //       <span className="img-toolbar__dropdown">
  //         <CustomIcon type={galleryTypes[Number(key) as galleryType].icon} />
  //         <span className="img-toolbar__dropdown-text">
  //           {galleryTypes[Number(key) as galleryType].title}
  //         </span>
  //       </span>
  //     ),
  //     onClick: ({ key }) => {
  //       onGalleryChange && onGalleryChange(galleryTypes[Number(key) as galleryType]);
  //       sendEvent(galleryTypes[Number(key) as galleryType].eventName);
  //     },
  //   });
  // });

  return items;
};

export const getAiGenItems = (
  onAiGenChange: (params: GalleryItem) => void,
  sendEvent: { (name: string, params?: any): Promise<void>; (arg0: string): void },
) => {
  const items: MenuProps['items'] = [];
  const IMG_GEN_TYPE = getImgGenTypes()
  Object.keys(IMG_GEN_TYPE).forEach((key) => {
    items.push({
      key: Number(key),
      label: (
        <span className="img-toolbar__dropdown">
          <CustomIcon type={IMG_GEN_TYPE[Number(key) as aiGenImgType.aiSearch].icon} />
          <span className="img-toolbar__dropdown-text ai-text-color">
            {IMG_GEN_TYPE[Number(key) as aiGenImgType.aiSearch].title}
          </span>
        </span>
      ),
      onClick: ({ key }) => {
        onAiGenChange && onAiGenChange(IMG_GEN_TYPE[Number(key) as aiGenImgType.aiSearch]);
        sendEvent(IMG_GEN_TYPE[Number(key) as aiGenImgType.aiSearch].eventName);
      },
    });
  });

  return items;
};

export const getEhanceItems = (
  onEnanceChange: (params: string) => void,
  sendEvent: { (name: string, params?: any): Promise<void>; (arg0: string): void },
  settings: EnhanceSettings,
) => {
  const { pptSearchEnhanceDesc, pptSearchEnhanceStyle } = settings ?? {};
  const items: MenuProps['items'] = [];
  const styleGroup: ItemType = {
    key: 'style',
    type: 'group',
    label: i18n.t('pages.presentation.enhanceStyle'),
    children: [],
  };
  const descriptionGroup: ItemType = {
    key: 'description',
    type: 'group',
    label: i18n.t('pages.presentation.enhanceDescription'),
    children: [],
  };
  const moreDescOptionsGroup: ItemType = {
    key: 'moreDescOptions',
    label: (
      <span className="present-panel__more-opts">
        <CustomIcon type="ellipsis" />
        <span className="img-toolbar__dropdown-text">
          {i18n.t('pages.presentation.moreOptions')}
        </span>
      </span>
    ),
    children: [],
  };
  const moreStyleOptionsGroup: ItemType = {
    key: 'moreStyleOptions',
    label: (
      <span className="present-panel__more-opts">
        <CustomIcon type="ellipsis" />
        <span className="img-toolbar__dropdown-text">
          {i18n.t('pages.presentation.moreOptions')}
        </span>
      </span>
    ),
    children: [],
  };
  let moreDescItems: SettingItem[] = [];
  if (pptSearchEnhanceDesc.length >= 5) {
    moreDescItems = pptSearchEnhanceDesc.splice(5);
  }
  let moreStyleItems: SettingItem[] = [];
  if (pptSearchEnhanceStyle.length >= 3) {
    moreStyleItems = pptSearchEnhanceStyle.splice(3);
  }
  const genEnhanceItem = (item: SettingItem, eventName: string) => {
    return {
      key: item.code,
      label: (
        <span className="img-toolbar__dropdown" style={{ marginInlineStart: -8 }}>
          {AI_ENHANCE_PARAMS[item.code]?.icon && (
            <CustomIcon type={AI_ENHANCE_PARAMS[item.code].icon} />
          )}
          <span className="img-toolbar__dropdown-text">{item.name}</span>
        </span>
      ),
      onClick: ({ key }: { key: string }) => {
        onEnanceChange && onEnanceChange(key);
        sendEvent(eventName, {
          value: key,
        });
      },
    };
  };

  pptSearchEnhanceDesc.forEach((item) => {
    descriptionGroup.children?.push(genEnhanceItem(item, 'SwitchAiSearchStyle'));
  });
  if (moreDescItems.length > 0) {
    moreDescItems.forEach((item) => {
      moreDescOptionsGroup.children?.push(genEnhanceItem(item, 'SwitchAiSearchStyle'));
    });
  }
  pptSearchEnhanceStyle.forEach((item) => {
    styleGroup.children?.push(genEnhanceItem(item, 'EnhanceAiSearchInPptPanel'));
  });
  if (moreStyleItems.length > 0) {
    moreStyleItems.forEach((item) => {
      moreStyleOptionsGroup.children?.push(genEnhanceItem(item, 'EnhanceAiSearchInPptPanel'));
    });
  }

  items.push(descriptionGroup);
  if (moreDescOptionsGroup.children?.length > 0) {
    items.push(moreDescOptionsGroup);
  }
  items.push(styleGroup);
  if (moreStyleOptionsGroup.children?.length > 0) {
    items.push(moreStyleOptionsGroup);
  }

  return items;
};
