import BulletList from '@tiptap/extension-bullet-list';

const PBulletList = BulletList.extend({
  name: 'bulletList',

  draggable: false,

  addAttributes() {
    return {
      id: {
        default: undefined,
        rendered: false,
      },
    };
  },

  //禁用input rules，-，+
  // addInputRules() {
  //   return [];
  // },
});

export default PBulletList;
