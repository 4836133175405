import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import './index.less';

interface ScaleContainerProps {
  className?: string;
  children?: (scale: number) => React.ReactNode;
}

const CONTAINER_WIDTH = 820;

const ScaleContainer: React.FC<ScaleContainerProps> = (props) => {
  const { className = '', children } = props;

  const [scale, setScale] = useState(1);
  const [height, setHeight] = useState(0);

  const scaleContainerRef = useRef<HTMLDivElement>(null);
  const scaleContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (scaleContainerRef?.current) {
        const { width } = scaleContainerRef?.current?.getBoundingClientRect();

        const scale = width / CONTAINER_WIDTH;

        scaleContainerRef?.current?.style?.setProperty('--container-width', `${width}px`);
        scaleContainerRef?.current?.style?.setProperty('--content-scale', `${scale}`);

        setScale(scale);
      }
    });

    if (scaleContainerRef?.current) {
      observer.observe(scaleContainerRef?.current);
    }

    return () => observer.disconnect();
  }, [scaleContainerRef?.current]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (scaleContentRef?.current) {
        const { height } = scaleContentRef?.current?.getBoundingClientRect();
        if (scale <= 1) {
          setHeight(height);
        }
      }
    });

    if (scaleContentRef?.current) {
      observer.observe(scaleContentRef?.current);
    }

    return () => observer.disconnect();
  }, [scaleContentRef?.current, scale]);

  return (
    <div
      className={classNames({
        'scale-container': true,
        [className]: !!className,
      })}
      style={{ maxHeight: height }}
      ref={scaleContainerRef}
    >
      <div className="scale-content" ref={scaleContentRef}>
        {children?.(scale)}
      </div>
    </div>
  );
};

export default ScaleContainer;
