// antd多语言 & dayjs多语言资源

export default {
  zh: {
    antdLocale: {
      resource: () => import('antd/locale/zh_CN'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/zh-cn'),
      key: 'zh-cn'
    },
  },
  en: {
    antdLocale: {
      resource: () => import('antd/locale/en_US'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/en'),
      key: 'en'
    },
  },
  ar: {
    antdLocale: {
      resource: () => import('antd/locale/ar_EG'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/ar'),
      key: 'ar',
    },
  },
  hi: {
    antdLocale: {
      resource: () => import('antd/locale/hi_IN'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/hi'),
      key: 'hi',
    }
  },
  es: {
    antdLocale: {
      resource: () => import('antd/locale/es_ES'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/es'),
      key: 'es'
    }
  },
  pt: {
    antdLocale: {
      resource: () => import('antd/locale/pt_PT'),
    },
    dayjsLocale: {
      resource: () => import('dayjs/locale/pt'),
      key: 'pt'
    }
  }
};
