import MetaPixel from '@/components/MetaPixel';
import BingAd from '@/components/BingAd';
import TwitterPixel from '@/components/TwitterPixel';
import LinkedInAd from '@/components/LinkedInAd';

const AdConversion = () => {
  return (
    <>
      <MetaPixel />
      <BingAd />
      <TwitterPixel />
      <LinkedInAd />
    </>
  );
};

export default AdConversion;
