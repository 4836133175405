import classNames from 'classnames';
import File from './File';
import Message from './Message';
import type { MessageItem, MediaFile } from '@/type';
import type { AvatarInfo, RenderOptions } from './Message';
import { State } from 'ahooks/es/useTextSelection';

import './index.less';

interface MessagesProps {
  className?: string;
  conversation?: string;
  showProcess?: boolean;
  isUpload?: boolean;
  isReady?: boolean;
  baseTime?: number;
  checkMode?: boolean;
  messages?: MessageItem[];
  checkedKeys?: string[];
  avatarInfo?: AvatarInfo;
  errorMsg?: string;
  forceBreak?: boolean;
  operatorShow?: boolean;
  answerDirection?: 'horizontal' | 'vertical';
  extraHeaderRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraBeforeContentRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraAfterContentRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraFooterRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraOperateRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraInnerOperateRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraBottomRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraFeedbackRender?: (message: MessageItem) => React.ReactNode;
  extraSelectionRender?: (message: MessageItem, selection: State) => React.ReactNode;
  onChecked?: (keys: string[]) => void;
  onBaseTimeChange?: (time: number) => void;
  onLoaded?: () => void;
  onCopied?: (copy: string) => void;
  onMessageSelect?: (message: MessageItem) => void;
  onTranslateContent?: (language: string, content?: string) => void;
  onFileCardClick?: (file: MediaFile, message?: MessageItem) => void;
}

export interface FileInfo {
  file_name: string;
  file_page_count: number;
  file_type: string;
  err_msg: string;
}

const Messages: React.FC<MessagesProps> = (props) => {
  const {
    className = '',
    conversation = '',
    baseTime = 0,
    isUpload = true,
    isReady = false,
    checkMode = false,
    messages = [],
    checkedKeys = [],
    avatarInfo,
    errorMsg,
    forceBreak,
    operatorShow,
    answerDirection = 'horizontal',
    extraHeaderRender,
    extraBeforeContentRender,
    extraAfterContentRender,
    extraFooterRender,
    extraOperateRender,
    extraInnerOperateRender,
    extraBottomRender,
    extraFeedbackRender,
    extraSelectionRender,
    onChecked,
    onBaseTimeChange,
    onLoaded,
    onCopied,
    onMessageSelect,
    onTranslateContent,
    onFileCardClick,
  } = props;

  const handleCheckedChange = (messageId: string, checked: boolean) => {
    let keys: string[] = [];

    if (checked) {
      keys = [...checkedKeys, messageId];
    } else {
      keys = checkedKeys.filter((key) => key !== messageId);
    }

    onChecked?.(keys);
  };

  const fileContentRender = (content: FileInfo, percent?: number) => {
    return (
      <File
        content={content}
        percent={percent}
        showProcess={false}
        errorMsg={errorMsg}
        isReady={isReady}
        forceBreak={forceBreak}
      />
    );
  };

  return (
    <div
      className={classNames({
        'message-list': true,
        [className]: !!className,
      })}
    >
      {messages?.map((message) => {
        return (
          <div
            className={classNames({
              'message-wrapper': true,
              'message-wrapper-hide': !!message.deleted,
            })}
            key={message?.messageId}
            id={message?.messageId}
          >
            <Message
              conversation={conversation}
              message={message}
              type={message?.roleEnum}
              checkMode={checkMode}
              isUpload={isUpload}
              isReady={isReady}
              baseTime={baseTime}
              checked={checkedKeys.includes(message?.messageId as string)}
              operatorShow={operatorShow}
              avatarInfo={avatarInfo}
              answerDirection={answerDirection}
              extraHeaderRender={extraHeaderRender}
              extraBeforeContentRender={extraBeforeContentRender}
              extraAfterContentRender={extraAfterContentRender}
              extraFooterRender={extraFooterRender}
              extraOperateRender={extraOperateRender}
              extraInnerOperateRender={extraInnerOperateRender}
              extraBottomRender={extraBottomRender}
              extraFeedbackRender={extraFeedbackRender}
              extraSelectionRender={extraSelectionRender}
              fileContentRender={
                message?.roleEnum === 'FAKE' || // pdf对话
                message?.roleEnum === 'HUMAN_FAKE' //文档生成ppt
                  ? fileContentRender
                  : null
              }
              onCheckedChange={handleCheckedChange}
              onBaseTimeChange={onBaseTimeChange}
              onLoaded={onLoaded}
              onCopied={onCopied}
              onMessageSelect={onMessageSelect}
              onTranslateContent={onTranslateContent}
              onFileCardClick={onFileCardClick}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Messages;
