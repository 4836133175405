import { ReactNode } from 'react';
import { isFunction } from 'lodash-es';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import GoogleIcons from '@/components/CustomIcon/GoogleIcons';

import './index.less';

interface ChatHeaderProps {
  gdriveFileTypeEnum?: string | undefined;
  type?: string | null;
  title?: string | ReactNode;
  className?: string;
  descriptionRender?: () => React.ReactNode;
  titleRender?: () => React.ReactNode;
  extraOperateRender?: () => React.ReactNode;
  onClosePreview?: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = (props) => {
  const {
    gdriveFileTypeEnum,
    type,
    title,
    className = '',
    descriptionRender,
    titleRender,
    extraOperateRender,
  } = props;
  // const iconType = useMemo(() => type ?? 'chat', [type]);

  return (
    <div
      className={classNames({
        'chat-view-header': true,
        [className]: !!className,
      })}
    >
      <div className="header-title">
        {(title || isFunction(titleRender)) && (
          <>
            {gdriveFileTypeEnum && (
              <GoogleIcons
                type={type || undefined}
                gdriveType={gdriveFileTypeEnum}
                size="chat-header"
              />
            )}
            {type && !gdriveFileTypeEnum && <CustomIcon className="header-icon" type={type} />}
            <div className="header-info ellipsis">
              {isFunction(titleRender) ? titleRender?.() : <h1 className="ellipsis">{title}</h1>}
              {isFunction(descriptionRender) && descriptionRender?.()}
            </div>
          </>
        )}
      </div>
      {isFunction(extraOperateRender) && extraOperateRender?.()}
    </div>
  );
};

export default ChatHeader;
