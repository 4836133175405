import { isNodeSelection, posToDOMRect } from '@tiptap/core';
import { EditorView } from '@tiptap/pm/view';
import {
  BubbleMenuView,
  BubbleMenuViewProps,
  BubbleMenuPluginProps,
} from '@tiptap/extension-bubble-menu';
import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state';
// import { posToDOMRect } from './posToDOMRect';

type PBubbleMenuViewProps = BubbleMenuViewProps & {
  scale?: number;
  containerLeft?: number;
  onShow?: () => void;
  onHide?: () => void;
};
type PBubbleMenuPluginProps = BubbleMenuPluginProps & {
  containerLeft?: number;
  scale?: number;
  onShow?: () => void;
  onHide?: () => void;
};
class PBubbleMenuView extends BubbleMenuView {
  scale: number;
  containerLeft: number;
  onShow: () => void;
  onHide: () => void;
  constructor({
    editor,
    element,
    view,
    tippyOptions = {},
    updateDelay = 250,
    shouldShow,
    scale = 1,
    containerLeft = 0,
    onShow = () => {},
    onHide = () => {},
  }: PBubbleMenuViewProps) {
    super({
      editor,
      element,
      view,
      tippyOptions,
      updateDelay,
      shouldShow,
    } as BubbleMenuViewProps);
    this.scale = scale;
    this.containerLeft = containerLeft;
    this.onShow = onShow;
    this.onHide = onHide;
  }

  updateHandler = (
    view: EditorView,
    selectionChanged: boolean,
    docChanged: boolean,
    oldState?: EditorState,
  ) => {
    const { state, composing } = view;
    const { selection } = state;

    const isSame = !selectionChanged && !docChanged;

    if (composing || isSame) {
      return;
    }

    this.createTooltip();

    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));

    const shouldShow = this.shouldShow?.({
      editor: this.editor,
      view,
      state,
      oldState,
      from,
      to,
    });

    if (!shouldShow) {
      this.hide();

      return;
    }

    this.tippy?.setProps({
      getReferenceClientRect:
        this.tippyOptions?.getReferenceClientRect ||
        (() => {
          if (isNodeSelection(state.selection)) {
            let node = view.nodeDOM(from) as HTMLElement;

            const nodeViewWrapper = node.dataset.nodeViewWrapper
              ? node
              : node.querySelector('[data-node-view-wrapper]');

            if (nodeViewWrapper) {
              node = nodeViewWrapper.firstChild as HTMLElement;
            }

            if (node) {
              return node.getBoundingClientRect();
            }
          }

          let { scale } = this;

          /**
           * tiptap: https://github.com/ueberdosis/tiptap/blob/develop/packages/core/src/helpers/posToDOMRect.ts
           * prosemirror coordsAtPos(): https://github.com/ProseMirror/prosemirror-view/blob/master/src/domcoords.ts
           */

          const { left, right, top, bottom, x, y, height, width } = posToDOMRect(view, from, to);

          const data = {
            // left: left / scale, //magic number to fix the issue with tooltip position
            /**
             * left 在原值与『容器左侧距离浏览器左边+200』取较大值
             */
            left: Math.max(left, this.containerLeft + 248) / scale,
            right: right / scale,

            top: top / scale,
            bottom: bottom / scale,
            height: height / scale,
            width: width / scale,
            x: x / 1,
            y: y / 1,
          };

          return { ...data, toJSON: () => data };
        }),
    });

    this.show();
  };
  setScale(value: number) {
    this.scale = value;
  }

  show() {
    if (this.tippy && !this.tippy.state.isDestroyed) {
      this.onShow?.();
      this.tippy?.show();
    } else {
      // throw Error('tp is destroied');
    }
  }

  hide() {
    if (this.tippy && !this.tippy.state.isDestroyed) {
      this.onHide?.();
      this.tippy?.hide();
    } else {
      // throw Error('tp is destroied');
    }
  }
  destroy() {
    if (this.tippy?.popper.firstChild) {
      (this.tippy.popper.firstChild as HTMLElement).removeEventListener(
        'blur',
        this.tippyBlurHandler,
      );
    }
    this.tippy?.destroy();
    this.element.removeEventListener('mousedown', this.mousedownHandler, { capture: true });
    this.view.dom.removeEventListener('dragstart', this.dragstartHandler);
    this.editor.off('focus', this.focusHandler);
    this.editor.off('blur', this.blurHandler);
  }
}

export const PBubbleMenuPlugin = (options: PBubbleMenuPluginProps) => {
  return new Plugin({
    key:
      typeof options.pluginKey === 'string' ? new PluginKey(options.pluginKey) : options.pluginKey,
    view: (view) => new PBubbleMenuView({ view, ...options }),
  });
};
