import { Node, mergeAttributes } from '@tiptap/core';

export interface CardBlockOptions {
  name?: string;
  HTMLAttributes: Record<string, any>;
}

const NODE_NAME = 'cardnode';

const CardNode = Node.create<CardBlockOptions>({
  name: NODE_NAME,

  priority: 1000,

  group: 'block',

  inline: false,

  content: 'block*',

  selectable: true,

  draggable: false,

  addOptions() {
    return {
      name: '',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
      },
      name: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div', attrs: { name: this.options.name ?? '' } }];
  },

  renderHTML({ node, HTMLAttributes }) {
    const name = node.attrs?.name;

    return name
      ? [
          'div',
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: `card-node node-${name}`,
          }),
          0,
        ]
      : ['span'];
  },
});

export default CardNode;
