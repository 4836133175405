import { Helmet } from 'react-helmet';

const TwitterPixel = () => {
  const { VITE_APP_TWITTER_PIXEL_ID, VITE_APP_TWITTER_PIXEL_ID1 } = import.meta.env;

  return VITE_APP_TWITTER_PIXEL_ID && VITE_APP_TWITTER_PIXEL_ID1 ? (
    <Helmet>
      <script>
        {`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','${VITE_APP_TWITTER_PIXEL_ID}');twq('config','${VITE_APP_TWITTER_PIXEL_ID1}');`}
      </script>
    </Helmet>
  ) : null;
};

export default TwitterPixel;
