import History from '@tiptap/extension-history';
import { redo, redoDepth, undo, undoDepth } from '@tiptap/pm/history';

const PHistory = History.extend({
  //处理undo 和redo的边界情况,防止undo超限导致内容被清空,防止redo超限导致重复undo
  addCommands() {
    return {
      undo:
        () =>
        ({ state, dispatch }) => {
          if (undoDepth(state) <= 1) {
            return true;
          }
          return undo(state, dispatch);
        },
      redo:
        () =>
        ({ state, dispatch }) => {
          if (redoDepth(state) == 0) {
            return true;
          }
          return redo(state, dispatch);
        },
    };
  },
});
export default PHistory;
