import { useCallback, useRef, useState } from 'react';
import useGASendEvent from '@/hooks/useGASendEvent';
import { FinishUploadParams, ImageUploadRef } from '@/components/ImageUpload';
import { getImageDimensions } from '../../tools';
import { ContentProps } from './index';

export const useImageToolContent = (params: ContentProps) => {
  const { onClick, onUploadFinished, onUploadStart } = params;
  const imageUploadRef = useRef<ImageUploadRef | null>(null);
  const [isUploadImage, setIsUploadImage] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const imageDimensions = useRef<{
    width: number;
    height: number;
    size: number;
    timestamp: number;
  }>({ width: 0, height: 0, size: 0, timestamp: 0 });
  const [isAiDropdownOpen, setIsAiDropdownOpen] = useState<boolean>(false);
  const { sendEvent } = useGASendEvent();

  const startImageUploadHandler = () => {
    setIsUploadImage(true);
    onUploadStart && onUploadStart();
  };

  const finishImageUploadHandler = useCallback(
    (params?: FinishUploadParams) => {
      if (params?.imageUrls && params?.imageUrls?.length > 0) {
        onUploadFinished && onUploadFinished(params);
        sendEvent('CompleteUploadImageInPpt', {
          url: params?.imageUrls[0],
          duration: Date.now() - imageDimensions.current.timestamp,
          width: imageDimensions.current.width,
          height: imageDimensions.current.height,
          size: imageDimensions.current.size,
          timestamp: Date.now(),
          name: params?.name,
        });
      }
      setIsUploadImage(false);
    },
    [imageDimensions],
  );

  const beforeUploadHandler = async (file: File) => {
    const { width, height } = await getImageDimensions(file);
    sendEvent('SelectImageInPptToolBar', {
      name: file.name,
      size: file.size,
      width: width,
      height: height,
      timestamp: Date.now(),
    });
    imageDimensions.current = {
      width,
      height,
      size: file.size,
      timestamp: Date.now(),
    };
  };

  const galleryOpenhandler = (params: boolean) => {
    setIsDropdownOpen(params);
    sendEvent('ClickGalleryListInPptToolBar', {
      open: params,
    });
  };

  const aiGenOpenHandler = (params: boolean) => {
    setIsAiDropdownOpen(params);
    sendEvent('ClickAiImageListInPptToolBar', {
      open: params,
    });
  };

  const handleItemClick = (type: string, e: React.MouseEvent) => {
    onClick && onClick({ ...e, type });
    if (type === 'delete') {
      sendEvent('ClickResetImageInPptToolBar');
    }
  };

  return {
    imageUploadRef,
    isUploadImage,
    isDropdownOpen,
    galleryOpenhandler,
    handleItemClick,
    startImageUploadHandler,
    beforeUploadHandler,
    finishImageUploadHandler,
    isAiDropdownOpen,
    aiGenOpenHandler,
  };
};
