import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { TPL_TYPE } from '@/common/config';

export type layout =
  | 'cover'
  | 'content'
  | 'emphasis'
  | 'single-text'
  | 'left-text'
  | 'back-cover'
  | 'outline'
  | 'default';

export interface CardOptions {
  onClick?: (evt: {
    attrs: Record<string, any>;
    view: any;
    pos: number;
    event: MouseEvent;
  }) => void;
  showLabel?: boolean;
  showLogo?: boolean;
  innerModel?: boolean;
  layout?: layout;
  index?: number;
  idPre?: string;
  img?: string;
  HTMLAttributes: Record<string, any>;
}

const NODE_NAME = 'card';

const Card = Node.create<CardOptions>({
  name: NODE_NAME,

  priority: 1000,

  group: 'block',

  inline: false,

  content: 'cardblock*',

  selectable: false,

  editable: false,

  draggable: false,

  addOptions() {
    return {
      id: `${NODE_NAME}-`,
      index: 0,
      layout: 'default',
      img: '',
      theme: '',
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      layout: {
        default: 'default',
      },
      id: {
        default: `${this?.options?.idPre ?? ''}${NODE_NAME}-`,
      },
      index: {
        default: 0,
      },
      img: {
        default: '',
        renderHTML(_attributes) {},
      },
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleClick(view, pos, event) {
            const attrs = editor.getAttributes('card');
            editor.storage?.onCardClick?.({ attrs, view, pos, event });
          },
        },
      }),
    ];
  },
  parseHTML() {
    return [
      {
        tag: 'div',
        attrs: { layout: this.options.layout ?? 'default' },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const { id, index, layout, img } = node?.attrs;
    const { showLogo, innerModel, showLabel, idPre } = this.editor?.state.doc.attrs || {};

    let hasTray = false;

    node.content.forEach((child) => {
      if (child?.attrs?.name === 'tray') {
        hasTray = true;
      }
    });

    //是否显示兜底图片
    const showDefaultImg = !img && !hasTray;

    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'presentation-card-wrapper',
        id: `${idPre}${id}`,
      }),
      [
        'div',
        { class: 'presentation-card-container ppt-card' },
        [
          'div',
          {
            class: `presentation-card card-layout-${layout}`,
            style: showDefaultImg ? '' : `background-image: none`,
          },
          0,
        ],
        img && !hasTray
          ? ['div', { class: 'card-image', style: `background-image: url(${img})` }]
          : ['span'],
      ],

      showLogo
        ? [
            'a',
            {
              href: `https://www.popai.pro/more/${TPL_TYPE.All}/Presentation?utm_source=madeWithPopaiBadge`,
            },
            ['div', { class: 'presentation-card-logo' }],
          ]
        : ['span'],
      showLabel ? ['div', { class: 'card-label' }, String(index)] : ['span'],
      innerModel ? ['div', { class: 'cn-logo-label' }] : ['span'],
    ];
  },

  // addNodeView() {
  //   return ReactNodeViewRenderer(ReactCardView);
  // },
});

export default Card;
