import { mergeAttributes } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';

const PParagraph = Paragraph.extend({
  name: 'paragraph',

  draggable: false,

  addAttributes() {
    return {
      id: {
        default: undefined,
        rendered: false,
      },
      styleType: '',
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    const { attrs } = node;

    return [
      `p`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: `ppt-p${attrs.styleType ? attrs.styleType : ''}`,
      }),
      0,
    ];
  },
});

export default PParagraph;
