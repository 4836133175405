import React, { useEffect, useRef, useState } from 'react';
import { Space, Divider } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomIcon from '@/components/CustomIcon';
import LanguageSelect from '@/components/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { useChatStore, useCommonStore } from '@/store';
import classNames from 'classnames';
import AddWriting from '@/layout/BasicLayout/components/AddWriting';
import { isFunction } from 'lodash-es';
import { useDA, useGASendEvent } from '@/hooks';

import { Language, Position } from '@/type';

import './index.less';

interface SelectionOperationsProps {
  /**触发场景，埋点使用 */
  currentScene?: string;
  selectionText?: string;
  messageId?: number;
  isUpload?: boolean;
  onTranslateContent?: (language: string, text?: string) => void;
  onCopy: (text: string) => void;
  hideTipAndSelection?: () => void;
  onGoogleSearch?: (query?: string) => void;
  onSaveFinish?: () => void;
}

const SelectionOperations: React.FC<SelectionOperationsProps> = (props) => {
  const {
    selectionText,
    messageId,
    isUpload,
    onTranslateContent,
    onCopy,
    onGoogleSearch,
    onSaveFinish,
  } = props;

  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { t } = useTranslation();

  const { selectionLanguage, setSelectionLanguage } = useChatStore();
  const pageLanguage = useCommonStore((state) => state.pageLanguage);

  const [addText, setAddText] = useState('');
  const [tipsPos, setTipsPos] = useState<Position>();

  const languageWrapperRef = useRef<HTMLButtonElement>(null);

  const handleAddFinish = (docId: string) => {
    setAddText('');
    onSaveFinish?.();

    sendDAEvent('TextSelectionFeatureButtonClick', {
      current_scene: props?.currentScene,
      target_language_choosed: selectionLanguage.languageEnglish,
      messageid: messageId,
      drag_content: selectionText,
      doc_id: docId,
      button_name: 'save',
    });
  };

  const calculateTipsPos = (event: React.MouseEvent) => {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const pos = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    setTipsPos(pos);
  };

  const handleSaveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    calculateTipsPos(event);
    sendEvent('SaveToWriting', {
      channelType: isUpload ? 'DOC' : 'CHAT',
    });
  };

  const languageBlockRender = (title: string, languages: Language[], callback?: () => void) => {
    return (
      <div className="language-group">
        <div className="language-group-title">{title}</div>
        {languages?.map((languageItem) => (
          <div
            className={classNames({
              'language-item': true,
              'language-item-active': languageItem?.langcode === selectionLanguage?.langcode,
            })}
            key={languageItem?.languageEnglish}
            onClick={() => {
              setSelectionLanguage(languageItem);
              onTranslateContent?.(languageItem?.languageEnglish as string, selectionText);
              callback?.();
            }}
          >
            {languageItem?.languageLabel}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (selectionText) {
      const defaultText = {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            attrs: {
              class: null,
              textAlign: 'left',
            },
            content: [
              {
                type: 'text',
                text: selectionText || '',
              },
            ],
          },
        ],
      };
      setAddText(JSON.stringify(defaultText));
    }
  }, [selectionText]);

  return (
    <Space.Compact block className="text-selection-operate">
      <>
        <button
          className="selection-operate-btn"
          type="button"
          // 防止pdf预览划词提前消失
          onMouseUp={(e) => e.stopPropagation()}
        >
          <AddWriting
            defaultTitle={selectionText?.replace(/\n/g, '')?.slice(0, 15)?.trim()}
            addText={addText}
            tipsPos={tipsPos}
            getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
            addFinishCallback={handleAddFinish}
            closeOperation={onSaveFinish}
            placement={pageLanguage === 'ar' ? 'bottomRight' : 'bottomLeft'}
          >
            <Space size={4} onClick={handleSaveClick}>
              <CustomIcon type="collect" className="selection-operate-icon" />
              {t('common.save')}
            </Space>
          </AddWriting>
        </button>
        <Divider type="vertical" className="operate-divider" />
      </>
      <button
        ref={languageWrapperRef}
        className="selection-operate-btn"
        onClick={() => {
          onTranslateContent?.(selectionLanguage.languageEnglish as string, selectionText);
        }}
      >
        <Space size={4}>
          <CustomIcon type="translate" className="selection-operate-icon" tooltip="Translate" />
          {selectionLanguage.languageEnglish}

          <LanguageSelect
            overlayClassName="translate-popover"
            placement="bottom"
            arrow={false}
            languageBlockRender={languageBlockRender}
            popupContainer={() => languageWrapperRef.current as HTMLButtonElement}
          >
            <div className="dropdown-icon-wrapper">
              <CustomIcon
                className="dropdown-icon"
                type="arrowBottomSquare"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </LanguageSelect>
        </Space>
      </button>
      <Divider type="vertical" className="operate-divider" />
      <CopyToClipboard text={selectionText as string} onCopy={onCopy}>
        <button className="selection-operate-btn" type="button">
          <Space size={4}>
            <CustomIcon type="copyNew" className="selection-operate-icon" />
            {t('common.copy')}
          </Space>
        </button>
      </CopyToClipboard>
      {isFunction(onGoogleSearch) && (
        <>
          <Divider type="vertical" className="operate-divider" />
          <button
            className="selection-operate-btn"
            type="button"
            onClick={() => onGoogleSearch?.(selectionText)}
          >
            <CustomIcon type="search" className="selection-operate-icon" tooltip="Search" />
          </button>
        </>
      )}
    </Space.Compact>
  );
};

export default SelectionOperations;
