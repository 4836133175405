import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import ReactImageView from './ReactImageView';

export interface CardBlockOptions {
  name?: string;
  HTMLAttributes: Record<string, any>;
}

const NODE_NAME = 'image';

const PImage = Node.create<CardBlockOptions>({
  name: NODE_NAME,

  group: 'block',

  inline: false,

  content: 'inline*',

  selectable: true,

  draggable: false,

  addAttributes() {
    return {
      id: {
        default: undefined,
      },
      src: {
        default: '',
      },
      dynamicThemeUrl: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div', attrs: { name: this.options.name ?? '' } }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      {},
      ['img', { src: node.attrs.src }],
      ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0],
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ReactImageView);
  },
});

export default PImage;
