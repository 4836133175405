import { Editor, NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Node } from '@tiptap/pm/model';
import classNames from 'classnames';
import ImgToolbar from '../../widgets/ImgToolbar';
import { addQueryParam, setNodeAttrs } from '@/components/PresentationView/tools';
import { FinishUploadParams } from '@/components/ImageUpload';
import { operationType } from '@/components/PresentationView/config';
import { GalleryItem } from '../../components/GalleryType';
import { useImageFocus } from './useImageView';
import { IMG_LOADING_TIMEOUT } from '@/components/PresentationViewV2/config';

import './index.less';

const DEFAULT_IMG =
  'https://popaife.s3.ap-southeast-1.amazonaws.com/prensentation/assets/ppt-img-default.png';
const DEFAULT_IMG_SIZE = {
  width: 1123,
  height: 633,
};

interface ImageStyle {
  width?: string;
  height?: string;
}

interface ReactImageViewProps {
  node: Node;
  editor: Editor;
  getPos: () => number;
}

export default (props: ReactImageViewProps) => {
  const { node, editor, getPos } = props;
  const [attrs, setAttrs] = useState(node.attrs);
  const [loading, setLoading] = useState(false);
  const [preLoadStatus, setPreLoadStatus] = useState<'no' | 'loading' | 'success' | 'error'>('no'); //图片预加载状态

  const [imgAspectStyle, setImgAspectStyle] = useState<ImageStyle>({
    width: '100%',
    height: '100%',
  });

  const container = useRef<HTMLElement>(null);
  const { showFocus, addClickOutside, removeClickOutside, setShowFocus, setFocuesRadius } =
    useImageFocus();

  const updateAttrs = (newAttrs: Record<string, any>) => {
    setAttrs({ ...attrs, ...newAttrs });
    setNodeAttrs({
      editor,
      node,
      attrs: newAttrs,
    });
  };

  const shuffleImg = (event: React.MouseEvent) => {
    if (!loading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      editor.chain().blur().run();
      editor.storage?.onImageClick?.({ editor, node, event, updateAttrs });
    }
  };

  //部分图片src中有变量{theme}需要替换成当前主题，图片内容随主题切换
  const parsedSrc = useMemo(() => {
    const { src } = attrs;
    const { theme } = editor.state.doc.attrs;

    return theme && src ? (src as string).replace('{theme}', theme) : src;
  }, [attrs.src]);

  useEffect(() => {
    setAttrs(node.attrs);
  }, [node.attrs.src]);

  const calculateAndSetAspectStyle = (imgWidth: number, imgHeight: number) => {
    const containerElement = container.current;
    if (containerElement) {
      const containerWidth = containerElement.clientWidth;
      const containerHeight = containerElement.clientHeight;
      const imgAspect = imgWidth / imgHeight;
      const containerAspect = containerWidth / containerHeight;
      let aspectStyle: ImageStyle =
        imgAspect > containerAspect
          ? { width: 'auto', height: '100%' }
          : { width: '100%', height: 'auto' };

      return aspectStyle;
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (!parsedSrc) {
      setPreLoadStatus('no');
      return;
    }
    const src = parsedSrc;
    const img = new Image();
    img.src = src;

    // 给图片增加超时时间，超时时间内没加载出来，展示兜底图
    const imageLoadingTimer = setTimeout(() => {
      if (['no', 'loading'].includes(preLoadStatus)) {
        img.src = '';
        setLoading(false);
        setPreLoadStatus('error');
      }
    }, IMG_LOADING_TIMEOUT);

    // setPreLoadStatus('loading');

    img.onload = () => {
      setImgAspectStyle(calculateAndSetAspectStyle(img.width, img.height));
      setLoading(false);
      setPreLoadStatus('success');
      clearTimeout(imageLoadingTimer);

      editor.storage?.onContentOperation?.({
        actionType: operationType.imgOnLoaded,
        payload: {
          imageUrl: img.src,
        },
      });
    };
    img.onerror = () => {
      setLoading(false);
      setPreLoadStatus('error');
    };
  }, [parsedSrc]);

  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let _entry of entries) {
          if (parsedSrc) {
            const img = new Image();
            img.src = parsedSrc;
            img.onload = () => {
              setImgAspectStyle(calculateAndSetAspectStyle(img.width, img.height));
            };
          }
        }
      });

      resizeObserver.observe(containerElement);

      return () => {
        if (containerElement) {
          resizeObserver.unobserve(containerElement);
        }
      };
    }
  }, [parsedSrc]);

  const toolBarHandler = (e: React.MouseEvent) => {
    if (e.type === 'shuffle') {
      shuffleImg(e);
    }
    if (e.type === 'delete') {
      deleteImgHandler();
    }
  };
  // 图片上传之后的回调事件，设置图片数据、记录图片的使用历史
  const uploadFinishedHandler = ({ imageUrls, name = '' }: FinishUploadParams) => {
    if (imageUrls && imageUrls.length > 0) {
      const url = addQueryParam(imageUrls[0], {
        name: name,
        mode: 'upload',
      });
      updateAttrs({ src: url });
      setLoading(false);
      editor.storage?.onUpdate?.({ editor });
      // 将上传的图片信息同步至组件外部
      editor.storage?.onContentOperation?.({
        actionType: operationType.imgUploadFinished,
        payload: {
          imageUrl: imageUrls[0],
          newImg: true,
          node,
        },
      });
    }
  };

  const uploadStartHandler = () => {
    setLoading(true);
  };

  const deleteImgHandler = () => {
    updateAttrs({ src: '' });
    editor.storage?.onUpdate?.({ editor });
  };
  // gallery 点击事件, 切换之后需要将选中的类型传给 image panel
  const galleryChangeHandler = (params: GalleryItem) => {
    setShowFocus(true);
    removeClickOutside();
    editor.storage?.onContentOperation?.({
      actionType: operationType.openGalleryPanel,
      payload: {
        galleryType: params,
        node,
        editor,
        pos: getPos(),
      },
    });
    addClickOutside();
    setTimeout(() => {
      setFocuesRadius(attrs.src);
    });
  };
  // 图片生成点击事件
  const aiChangeHandler = (params: GalleryItem) => {
    setShowFocus(true);
    removeClickOutside();
    editor.storage?.onContentOperation?.({
      actionType: operationType.openAiGenPanel,
      payload: {
        aiGenType: params,
        node,
        editor,
        pos: getPos(),
      },
    });
    addClickOutside();
    setTimeout(() => {
      setFocuesRadius(attrs.src);
    });
  };

  const ImgLoading = () => (
    <span className="p-image-wrapper--loading">
      <span></span>
      <span>Loading...</span>
    </span>
  );

  // 在非编辑模式下，不渲染图片工具条
  return (
    <NodeViewWrapper
      className={classNames('p-image-wrapper', {
        'p-image-none': preLoadStatus === 'no',
      })}
      ref={container}
    >
      {showFocus && <div id={attrs.src} className="p-image-wrapper--focus"></div>}
      {loading && <ImgLoading />}
      {/* 阿语环境下不渲染图片工具条 */}
      {editor.isEditable ? (
        <ImgToolbar
          onClick={toolBarHandler}
          onUploadStart={uploadStartHandler}
          onUploadFinished={uploadFinishedHandler}
          onGalleryChange={galleryChangeHandler}
          isImageClear={!node.attrs.src}
          onAiGenChange={aiChangeHandler}
        >
          <section
            className="p-image-content"
            contentEditable="false"
            suppressContentEditableWarning={true}
          >
            {['success', 'error'].includes(preLoadStatus) && (
              <img
                src={preLoadStatus === 'error' ? DEFAULT_IMG : parsedSrc}
                style={
                  preLoadStatus === 'error'
                    ? calculateAndSetAspectStyle(DEFAULT_IMG_SIZE.width, DEFAULT_IMG_SIZE.height)
                    : imgAspectStyle
                }
              />
            )}
          </section>
        </ImgToolbar>
      ) : (
        <div
          className="p-image-content"
          contentEditable="false"
          suppressContentEditableWarning={true}
        >
          {['success', 'error'].includes(preLoadStatus) && (
            <img
              src={preLoadStatus === 'error' ? DEFAULT_IMG : parsedSrc}
              style={
                preLoadStatus === 'error'
                  ? calculateAndSetAspectStyle(DEFAULT_IMG_SIZE.width, DEFAULT_IMG_SIZE.height)
                  : imgAspectStyle
              }
            />
          )}
        </div>
      )}
      {/* <NodeViewContent /> */}
    </NodeViewWrapper>
  );
};
