import {
  Color,
  Document,
  Dropcursor,
  // Figcaption,
  // FileHandler,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  SpaceCommand,
  ZeroWidthCommand,
  StarterKit,
  // Subscript,
  // Superscript,
  Table,
  // TableOfContents,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  Columns,
  Column,
  TaskItem,
  TaskList,
  CustomSelectAll,
  // Image,
  // ImageBlock,
  // ImageUpload,
  ListKeymap,
  Callout,
  TableOfContents,
  Code,
  CodeBlock,
} from '.';
import { Markdown } from 'tiptap-markdown';
import i18n from '@/i18n';

export const ExtensionKit = () => [
  Document,
  Column,
  Columns,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    codeBlock: false,
    code: false,
  }),
  TextStyle.configure({
    HTMLAttributes: {
      class: 'text-style',
    },
  }),
  FontSize,
  FontFamily,
  Color,
  TrailingNode,
  Link,
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlight-style',
    },
  }),
  Underline,
  // Image,
  // ImageBlock比ImageUpload优先级高
  // ImageBlock,
  // ImageUpload,
  // TableOfContentsNode,
  // FileHandler.configure({
  //   allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
  //   onDrop: (currentEditor, files, pos) => {
  //     files.forEach(async () => {
  //       const url = await API.uploadImage();

  //       currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run();
  //     });
  //   },
  //   onPaste: (currentEditor, files) => {
  //     files.forEach(async () => {
  //       const url = await API.uploadImage();

  //       return currentEditor
  //         .chain()
  //         .setImageBlockAt({ pos: currentEditor.state.selection.anchor, src: url })
  //         .focus()
  //         .run();
  //     });
  //   },
  // }),
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  // Subscript,
  // Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: true,
    placeholder: ({ editor }) => {
      const isColumn = editor.isActive('column');
      if (isColumn) {
        return i18n.t('components.write.placeholderColumn');
      }
      return i18n.t('components.write.placeholderDefault');
      // TODO: 空行才显示 slash menu，默认 placeholder 不显示
    },
  }),
  SlashCommand,
  SpaceCommand,
  ZeroWidthCommand,
  Focus,
  // Figcaption,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor',
  }),
  Markdown,
  CustomSelectAll,
  ListKeymap,
  Callout,
  TableOfContents,
  Code,
  CodeBlock,
];

export default ExtensionKit;
