import { useEffect, useMemo } from 'react';
import { Editor as CoreEditor } from '@tiptap/core';
import Focus from '@tiptap/extension-focus';
import Text from '@tiptap/extension-text';
import { Transaction } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { PresentationOptions } from '../types';

import { setDocAttrs } from '../tools';
import * as Extensions from '../extensions';

type EditorOptions = Pick<
  PresentationOptions,
  | 'idPre'
  | 'editable'
  | 'showLogo'
  | 'innerModel'
  | 'showLabel'
  | 'headingLineBreak'
  | 'theme'
  | 'onBeforeCreate'
  | 'onUpdate'
  | 'onCardClick'
  | 'onImageClick'
  | 'onContentOperation'
>;

const createEditor = (options: EditorOptions) => {
  const { editable, onBeforeCreate } = options;

  const editor: Editor = new Editor({
    extensions: [
      Extensions.Doc,
      Extensions.Card,
      Extensions.CardBlock,
      Extensions.CardNode,
      Extensions.PHeading,
      Extensions.PBulletList,
      Extensions.PListItem,
      Extensions.PParagraph,
      Extensions.PImage,
      Extensions.PHighlight.configure({ multicolor: true }) /** Mark */,
      Extensions.PHistory,
      Text,
      Extensions.PBold,
      Focus.configure({
        className: 'current-focus',
        mode: 'all',
      }),
    ],

    editable: editable,
    // autofocus: true,
    enablePasteRules: false,
    enableInputRules: false,
    injectCSS: false,
    onBeforeCreate: ({ editor }) => {
      onBeforeCreate?.({ editor });
    },

    onUpdate: (e: { editor: CoreEditor; transaction: Transaction }) => {
      // 输出包含高亮标记的选区范围
      if (e.editor) {
        const text = e.editor?.getText() ?? '';
        const preText = e.editor?.storage?.preText ?? '';
        if (preText && text !== preText) {
          e.editor.storage.onUpdate?.(e);
        }
        e.editor.storage.preText = text;
      }
    },
    onCreate: ({ editor }) => {
      editor.isEditable && editor.chain().focus().run();
    },
  });

  return editor;
};

export const useEditor = (options: EditorOptions) => {
  const {
    editable,
    idPre,
    showLogo,
    innerModel,
    showLabel,
    headingLineBreak,
    theme,
    onCardClick,
    onImageClick,
    onUpdate,
    onContentOperation,
  } = options;

  const editor = useMemo(() => createEditor(options), []);

  useEffect(() => {
    editor.storage.onCardClick = onCardClick;
  }, [onCardClick]);

  useEffect(() => {
    editor.storage.onImageClick = onImageClick;
  }, [onImageClick]);

  useEffect(() => {
    editor.storage.onUpdate = onUpdate;
  }, [onUpdate]);

  useEffect(() => {
    editor.storage.onContentOperation = onContentOperation;
  }, [onContentOperation]);

  useEffect(() => {
    editor.setEditable(editable ?? false);
  }, [editable]);

  useEffect(() => {
    setDocAttrs(editor, {
      idPre,
      showLogo,
      innerModel,
      showLabel,
      headingLineBreak,
    });
  }, [showLogo, innerModel, showLabel, headingLineBreak, idPre]);

  useEffect(() => {
    Promise.resolve().then(() => {
      setDocAttrs(editor, {
        theme,
      });
    });
  }, [theme]);

  return editor;
};
