const IMG_EMPTY =
  'https://popai-file.s3-accelerate.amazonaws.com/pptProjectImg/9755c3b7-4bbf-4fdc-ad17-bc84b43b0b96/566c5d84-e064-424a-b2d5-48449e2343ec/a0f3c8ef041e366da3081b74d00f9708?name=%E5%8D%A0%E4%BD%8D%E5%9B%BE.png';

export const tokenToCss = (tokens: any) => {
  let cssContent = '';
  for (const category in tokens) {
    if (category.includes('--popai-ppt')) {
      cssContent += `${category}: ${tokens[category]};\n`;
    } else {
      cssContent += `--popai-ppt-${category}: ${tokens[category]};\n`;
    }
  }
  return cssContent;
};

export function addStyleToBody(uClassName: string, css: string) {
  let styleElement = document.createElement('style');
  styleElement.textContent = `div[presentation-theme='${uClassName}'].presentation-container-theme-${uClassName}:has(.presentation-content), .ppt-export-modal-history-list .theme-${uClassName} {\n${css}}`;
  styleElement.id = uClassName;
  document.head.appendChild(styleElement);
}

function getStyleObject(element: Element, pseudo?: string) {
  const style = window.getComputedStyle(element, pseudo);

  let backgroundColor = '';
  if (!style.backgroundColor.includes('rgba')) {
    // @ts-ignore
    const [r, g, b] = style?.backgroundColor?.match(/\d+/g)?.map(Number);
    backgroundColor = `rgba(${r}, ${g}, ${b}, 1)`;
  } else {
    backgroundColor = style.backgroundColor;
  }

  return {
    width: parseFloat(style.width),
    height: parseFloat(style.height),
    borderRadius: parseFloat(style.borderRadius),
    borderWidth: parseFloat(style.borderWidth),
    borderColor: style.borderColor,
    backgroundColor: backgroundColor,
    color: style.color,
    fontSize: parseFloat(style.fontSize),
    lineHeight: 100, // 百分比，下载时行高默认设置为 1
    fontWeight: parseFloat(style.fontWeight),
    backgroundImage: style.backgroundImage,
    left: parseFloat(style.left),
    right: parseFloat(style.right),
    top: parseFloat(style.top),
    bottom: parseFloat(style.bottom),
    display: style.display,
    textAlign: style.textAlign,
    fontFamily: style.fontFamily,
    minHeight: parseFloat(style.minHeight),
    bold: parseFloat(style.fontWeight) >= 500,
  };
}

// 计算 PPT 内容元素的位置，获取渲染 PPTX 所需元素
export function getPPTCardElement(element: Element) {
  const pptCards = Array.from(element.querySelectorAll('.ppt-card'));
  const pptCardStyle = getStyleObject(pptCards[0]);
  const pptSchema: any = {
    type: 'ppt',
    style: {
      width: pptCardStyle.width,
      height: pptCardStyle.minHeight,
    },
    childNodes: [],
  };

  const getBgImage = (url: string) => {
    const regex = /url\((["'])(.*?)["']\)/;
    return url.match(regex)?.[2] || '';
  };

  const getWatermarkNode = () => {
    return {
      type: 'picture',
      style: {
        role: 'watermark',
        x: 36,
        y: 412,
        width: 174,
        height: 30,
      },
      content: 'https://popaife.s3-accelerate.amazonaws.com/prensentation/assets/watermaskdark.png',
    };
  };

  const getShapeNode = (el: Element) => {
    const className = el.className;
    const elRect = el.getBoundingClientRect();
    const afterNodeList: Array<{ nodeType: string; style: any } | null> = [];
    if (
      className.includes('card-layout-cover-list') ||
      className.includes('card-layout-emphasis-list')
    ) {
      // 装饰元素
      const style = getStyleObject(el, '::after');
      const afterNode = {
        nodeType: 'after',
        style: {
          ...style,
          x: style.left,
          y: style.top,
        },
      };
      afterNodeList.push(afterNode);
      // 文本背景元素
      const headerNode = el.querySelector('.card-block-header') as HTMLElement;
      const bodyNode = el.querySelector('.card-block-body') as HTMLElement;

      if (headerNode && bodyNode) {
        const headerNodeRect = headerNode?.getBoundingClientRect();
        const headerNodeStyle = getStyleObject(headerNode);
        const bodyNodeStyle = getStyleObject(bodyNode);
        const textBgNode = {
          nodeType: 'after',
          style: {
            ...headerNodeStyle,
            height: headerNodeStyle.height + bodyNodeStyle.height,
            x: headerNodeRect.x - elRect.x,
            y: headerNodeRect.y - elRect.y,
          },
        };
        if (textBgNode.style.backgroundColor !== 'rgba(0, 0, 0, 0)') {
          afterNodeList.push(textBgNode);
        }
      }
    } else if (className.includes('card-layout-row-img-list')) {
      // 图文列表的底部装饰
      const nodeListItem = Array.from(el?.getElementsByClassName('node-list-item') || []);
      // 误差调整数值
      const boundingHeight = 10;
      nodeListItem.forEach((nodeListItem) => {
        if (nodeListItem) {
          const nodeItemRect = nodeListItem.getBoundingClientRect();
          const nodeItemX = nodeItemRect.x - elRect.x;
          const nodeItemY = nodeItemRect.y - elRect.y;
          const nodeItemHeight = nodeItemRect.height;
          const afterNodeStyle = getStyleObject(nodeListItem, '::after');
          const afterNode = {
            nodeType: 'after',
            style: {
              ...afterNodeStyle,
              x: nodeItemX,
              y: nodeItemY + nodeItemHeight + afterNodeStyle.height + boundingHeight,
            },
          };
          afterNodeList.push(afterNode);
        }
      });
    } else if (className.includes('card-layout-content-list')) {
      // 标题背景元素
      const headerNode = el.querySelector('.card-block-header') as HTMLElement;
      if (headerNode) {
        const headerNodeRect = headerNode?.getBoundingClientRect();
        const headerNodeStyle = getStyleObject(headerNode);
        const titleNodeBg = {
          nodeType: 'after',
          style: {
            ...headerNodeStyle,
            x: headerNodeRect.x - elRect.x,
            y: headerNodeRect.y - elRect.y,
          },
        };
        if (titleNodeBg.style.backgroundColor !== 'rgba(0, 0, 0, 0)') {
          afterNodeList.push(titleNodeBg);
        }
      }
    } else if (className.includes('card-layout-column-pro-list')) {
      const nodeListItem = Array.from(el?.getElementsByClassName('node-list-item') || []);
      nodeListItem.forEach((nodeListItem) => {
        if (nodeListItem) {
          const nodeItemRect = nodeListItem.getBoundingClientRect();
          const nodeItemX = nodeItemRect.x - elRect.x;
          const nodeItemY = nodeItemRect.y - elRect.y;
          const afterNodeStyle = getStyleObject(nodeListItem, '::after');
          const afterNode = {
            nodeType: 'after',
            style: {
              ...afterNodeStyle,
              x: nodeItemX,
              y: nodeItemY + afterNodeStyle.top,
            },
          };
          afterNodeList.push(afterNode);
        }
      });
    } else if (className.includes('card-layout-swot')) {
      const nodeListItem = Array.from(el?.getElementsByClassName('node-list-item') || []);
      nodeListItem.forEach((nodeListItem) => {
        if (nodeListItem) {
          const nodeItemRect = nodeListItem.getBoundingClientRect();
          const nodeItemX = nodeItemRect.x - elRect.x;
          const nodeItemY = nodeItemRect.y - elRect.y;
          const afterNodeStyle = getStyleObject(nodeListItem, '::after');
          const afterNode = {
            nodeType: 'after',
            style: {
              ...afterNodeStyle,
              x: nodeItemX + afterNodeStyle.left,
              y: nodeItemY,
            },
          };
          afterNodeList.push(afterNode);
        }
      });
    } else if (className.includes('card-layout-row-pro-list')) {
      const nodeListItem = Array.from(el?.getElementsByClassName('node-list-item') || []);
      nodeListItem.forEach((nodeListItem) => {
        if (nodeListItem) {
          const nodeItemRect = nodeListItem.getBoundingClientRect();
          const nodeItemX = nodeItemRect.x - elRect.x;
          const nodeItemY = nodeItemRect.y - elRect.y;
          const afterNodeStyle = getStyleObject(nodeListItem, '::after');
          // 伪元素节点
          const afterNode = {
            nodeType: 'after',
            style: {
              ...afterNodeStyle,
              x: nodeItemX,
              y: nodeItemY + afterNodeStyle.top,
            },
          };
          afterNodeList.push(afterNode);
          // item 节点，获取 border，并转换为图形,border 展示出来是描边样式
          const nodeStyle = getStyleObject(nodeListItem);
          if (nodeStyle.borderWidth > 0) {
            const bgNode = {
              nodeType: 'after',
              style: {
                ...nodeStyle,
                x: nodeItemX,
                y: nodeItemY,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                backgroundImage: 'none',
              },
            };
            afterNodeList.push(bgNode);
          }
        }
      });
    } else {
      afterNodeList.push(null);
    }
    return afterNodeList;
  };

  const getLayoutName = (el: Element) => {
    if (el.parentElement) {
      return el.parentElement.getAttribute('layout');
    }
  };

  const getNodeId = (el: Element) => {
    let nodeId = '';
    if (
      el.parentElement &&
      (el.parentElement.className.includes('card-node') ||
        el.parentElement.className.includes('presentation-card-wrapper'))
    ) {
      nodeId = el.parentElement.getAttribute('id') || '';
    } else {
      nodeId = el.getAttribute('id') || '';
    }

    return nodeId;
  };

  const isSwot = (el: Element) => {
    if (el) {
      let parent = el.parentElement;
      while (parent && !parent.classList.contains('card-layout-swot')) {
        parent = parent.parentElement;
      }
      if (parent) return true;
    }
    return false;
  };

  pptCards.forEach((pptCard) => {
    const pptCardRect = pptCard.getBoundingClientRect();
    const elementNodes: Element | any[] = [];
    const slideChildNodes: any[] = [];
    // 要注意放入数组时的顺序，最后放入的渲染在上层
    // 取背景图，将 card 容器放入 slide 数组
    elementNodes.push(pptCard);
    // 取背景颜色
    elementNodes.push(...Array.from(pptCard?.getElementsByClassName('presentation-card') || []));
    // 取装饰图
    elementNodes.push(...Array.from(pptCard?.getElementsByClassName('card-block-tray') || []));
    // 取 bullets 背景色和背景图
    elementNodes.push(
      ...Array.from(
        pptCard
          ?.getElementsByClassName('presentation-card')[0]
          ?.getElementsByClassName('node-list-item') || [],
      ),
    );
    // 获取卡片中的可转换为 shape 的元素，主要是伪元素
    const afterElement = getShapeNode(pptCard?.getElementsByClassName('presentation-card')[0]);
    elementNodes.push(...afterElement);
    // 取图文列表中的图片
    elementNodes.push(
      ...Array.from(
        pptCard
          ?.getElementsByClassName('card-block-body')[0]
          ?.getElementsByClassName('p-image-content') || [],
      ),
    );
    elementNodes.push(...Array.from(pptCard?.getElementsByTagName('h1') || []));
    elementNodes.push(...Array.from(pptCard?.getElementsByTagName('h2') || []));
    elementNodes.push(...Array.from(pptCard?.getElementsByTagName('h3') || []));
    elementNodes.push(...Array.from(pptCard?.getElementsByTagName('h5') || []));
    elementNodes.push(...Array.from(pptCard?.getElementsByTagName('p') || []));

    elementNodes.forEach((node) => {
      if (!node) return;

      if (node.nodeType && node.nodeType === 'after') {
        if (node.style?.backgroundImage && node.style?.backgroundImage !== 'none') {
          slideChildNodes.push({
            type: 'picture',
            style: node.style,
            content: getBgImage(node.style.backgroundImage),
          });
        } else {
          slideChildNodes.push({
            type: 'shape',
            style: node.style,
          });
        }
      } else {
        const nodeRect = node?.getBoundingClientRect();
        let nodeComputedStyle = getStyleObject(node);
        if (nodeComputedStyle.display === 'none') {
          return;
        }

        const x = nodeRect?.left - pptCardRect.left;
        const y = nodeRect?.top - pptCardRect.top;
        let content = '';
        let type = '';
        let nodeId = getNodeId(node);

        const nodeType =
          node.nodeName === 'IMG'
            ? 'IMG'
            : node.className.includes('ppt-card')
            ? 'ppt-card'
            : node.className.includes('presentation-card')
            ? 'presentation-card'
            : node.className.includes('card-block-tray')
            ? 'card-block-tray'
            : node.className.includes('node-list-item')
            ? 'node-list-item'
            : node.className.includes('p-image-content')
            ? 'p-image-content'
            : 'other';

        switch (nodeType) {
          case 'ppt-card':
            // 获取背景图
            if (
              nodeComputedStyle?.backgroundImage &&
              nodeComputedStyle?.backgroundImage !== 'none'
            ) {
              content = getBgImage(nodeComputedStyle?.backgroundImage);
            }
            type = 'picture';
            // pptx 卡片无法设置圆角，因此背景图也不展示圆角
            nodeComputedStyle.borderRadius = 0;
            break;
          case 'presentation-card':
            // 背景矩形
            type = 'shape';
            break;
          case 'node-list-item':
            // 先看有没有背景图
            if (
              nodeComputedStyle?.backgroundImage &&
              nodeComputedStyle?.backgroundImage !== 'none'
            ) {
              content = getBgImage(nodeComputedStyle?.backgroundImage);
              type = 'picture';
            } else {
              type = 'shape';
            }
            break;
          case 'card-block-tray':
            // tray 元素中的图片
            const imgs = node.getElementsByTagName('img')[0];
            if (imgs) {
              content = imgs.src;
            } else {
              // 如果没有图片元素，则展示占位图
              // if (
              //   nodeComputedStyle?.backgroundImage &&
              //   nodeComputedStyle?.backgroundImage !== 'none'
              // ) {
              //   content = getBgImage(nodeComputedStyle?.backgroundImage);
              // }
              content = IMG_EMPTY;
            }
            type = 'picture';
            break;
          case 'p-image-content':
            const img = node.getElementsByTagName('img')[0];
            if (img) {
              content = (img as HTMLImageElement)?.src || '';
            } else {
              content = IMG_EMPTY;
            }
            type = 'picture';
            break;
          case 'other':
            // 去掉文字换行
            content = node.textContent.replace(/\n/g, '') || '';
            if (isSwot(node) && node.nodeName === 'H1') {
              // 微调 swot 中的行高
              nodeComputedStyle.lineHeight = 140;
            }
            type = 'text';
            break;
        }

        if (content || type === 'shape') {
          slideChildNodes.push({
            type,
            nodeId,
            style: {
              ...nodeComputedStyle,
              x,
              y,
            },
            content,
          });
        }
      }
    });
    // 水印节点
    slideChildNodes.push(getWatermarkNode());

    pptSchema.childNodes.push({
      type: 'slide',
      layoutName: getLayoutName(pptCard),
      nodeId: getNodeId(pptCard).replace('preview-', ''),
      style: {
        ...getStyleObject(pptCard),
        borderRadius: 0,
      },
      childNodes: slideChildNodes,
    });
  });
  return pptSchema;
}
