import { memo, useState, useRef, useEffect, useMemo, useCallback, lazy, Suspense } from 'react';
import { Space, Spin } from 'antd';
import { isFunction, debounce } from 'lodash-es';
import classNames from 'classnames';
import { useUpdateEffect, useLongPress, useTextSelection } from 'ahooks';
import { useDA, useGASendEvent, useProgress } from '@/hooks';
import { getDevice, getFileType, isArabic } from '@/common/utils';
import Checkbox from '@/components/CheckBox';
import UserAvatar from '@/components/UserAvatar';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { useChatStore } from '@/store';
import File from './File';
import SelectionOperationPopover, {
  SelectionOperationPopoverRef,
} from '../SelectionOperationPopover';
import { EMediaType, type MessageItem, MediaFile, QuoteInfoItem } from '@/type';
import type { FileInfo } from './';
import { State } from 'ahooks/es/useTextSelection';
import SearchTip from './SearchTip';

import './index.less';

const Markdown = lazy(() => import('@/components/Markdown'));

interface MessageProps {
  conversation?: string;
  message: MessageItem;
  type?: string;
  checkMode?: boolean;
  checked?: boolean;
  isUpload?: boolean;
  isReady?: boolean;
  baseTime?: number;
  avatarInfo?: AvatarInfo;
  operatorShow?: boolean;
  answerDirection?: 'horizontal' | 'vertical';
  extraHeaderRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraBeforeContentRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraAfterContentRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraFooterRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraOperateRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraInnerOperateRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraBottomRender?: (message: MessageItem, options?: RenderOptions) => React.ReactNode;
  extraSelectionRender?: (message: MessageItem, selection: State) => React.ReactNode;
  extraFeedbackRender?: (message: MessageItem) => React.ReactNode;
  fileContentRender?: ((content: FileInfo, percent?: number) => React.ReactNode) | null;
  onCheckedChange?: (messageId: string, checked: boolean) => void;
  onBaseTimeChange?: (time: number) => void;
  onLoaded?: () => void;
  onCopied?: (copy: string) => void;
  onMessageSelect?: (message: MessageItem) => void;
  onAddWrite?: (text: string) => void;
  onTranslateContent?: (language: string, content?: string) => void;
  onFileCardClick?: (file: MediaFile, message?: MessageItem) => void;
}

export interface RenderOptions {
  [key: string]: any;
}

export interface AvatarInfo {
  src?: string | null;
  aiInfo?: {
    name?: string;
    style?: React.CSSProperties;
  };
  humanInfo?: {
    name?: string;
    style?: React.CSSProperties;
  };
}

const PROMPT_MESSAGE_ONE_LINE_HEIGHT = 44;
const PROMPT_MESSAGE_OPERATE_WIDTH = 96;

const mediaTypeMap = {
  OUTLINE: {
    type: 'outline',
    desc: 'Outline',
  },
  SLIDES: {
    type: 'ppt',
    desc: 'Presentation',
  },
};

const Message: React.FC<MessageProps> = memo((props) => {
  const {
    conversation = '',
    message,
    type,
    checkMode,
    checked,
    isUpload,
    isReady,
    baseTime = 0,
    avatarInfo,
    operatorShow,
    answerDirection = 'horizontal',
    extraHeaderRender,
    extraBeforeContentRender,
    extraAfterContentRender,
    extraFooterRender,
    extraOperateRender,
    extraInnerOperateRender,
    extraBottomRender,
    extraFeedbackRender,
    extraSelectionRender,
    fileContentRender,
    onCheckedChange,
    onBaseTimeChange,
    onLoaded,
    onCopied,
    onMessageSelect,
    onTranslateContent,
    onFileCardClick,
  } = props;
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();

  const { selectionLanguage, channel, isOneChatChannel, setPptChatClickMediaType } = useChatStore();

  const [hoverClass, setHoverClass] = useState('');
  const [rightPos, setRightPos] = useState(0);
  const [currentMessageId, setCurrentMessageId] = useState('');
  const [chooseLoading, setChooseLoading] = useState(false);
  const [promptOperateVisibleMobile, setPromptOperateVisibleMobile] = useState(false);
  const [mediaListLaunch, setMediaListLaunch] = useState(false);
  const [canContainMaxCardNum, setCanContainMaxCardNum] = useState<number>(3);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLDivElement>(null);
  const mediaListRef = useRef<HTMLDivElement>(null);

  const selectionOperationRef = useRef<SelectionOperationPopoverRef>({});

  const selection = useTextSelection(msgRef);

  const inSharePage = useMemo(() => {
    return location.pathname.includes('share.html');
  }, []);

  const messageList = useMemo(() => {
    if (message?.subMessageList) {
      return message?.subMessageList
        ?.reduce((memo, current) => (memo.push(current), memo), [message])
        ?.map((item) => Object.assign(item, { comparison: true }));
    } else {
      return [];
    }
  }, [message]);

  const tipTittle = useMemo(() => {
    // MULTI_CHAT MULTI_SUMMARY MULTI_DOC_CHAT MULTI_PPT_OUTLINE MULTI_PPT_CONTENT
    switch (message?.actionResult) {
      case 'MULTI_CHAT':
      case 'MULTI_DOC_CHAT':
        return 'Which response do you prefer?';
      case 'MULTI_SUMMARY':
        return 'Which summarization do you prefer?';
      default:
        return '';
    }
  }, [message?.actionResult]);

  const messageCanSelect = useMemo(() => {
    switch (message?.actionResult) {
      case 'MULTI_PPT_OUTLINE':
      case 'MULTI_PPT_CONTENT':
        return !message?.processing;
      default:
        return false;
    }
  }, [message]);

  const operateOption = useMemo(() => {
    switch (type) {
      case 'HUMAN':
        return {
          hoverClass,
          rightPos,
          excludeTarget: msgRef?.current,
          visible: promptOperateVisibleMobile,
          onVisibleChange: setPromptOperateVisibleMobile,
        };
      default:
        return {};
    }
  }, [type, hoverClass, rightPos, promptOperateVisibleMobile, msgRef?.current]);

  const isNotPPTChannel = useMemo(() => {
    return channel?.fileTypeEnum !== 'ppt' || channel?.docEntries?.multiDoc;
  }, [channel]);

  const { progress, time, stop } = useProgress({
    autoStart: isUpload && message?.roleEnum === 'FAKE' && !isReady,
    time: baseTime,
  });

  const closeOperatePopover = () => {
    selectionOperationRef?.current?.setOperateOpen?.(false);
  };

  useLongPress(() => setPromptOperateVisibleMobile(true), msgRef, {
    moveThreshold: { x: 7, y: 7 },
  });

  const getMessageContentText = (message: MessageItem) => {
    switch (message?.actionResult) {
      case 'MULTI_PPT_OUTLINE':
        return {
          content: '',
        };
      default:
        return {
          content: message?.content as string,
          quoteInfoList: message?.quoteInfoList as QuoteInfoItem[],
          intentName: (message?.intentName as string) || message?.intentInfo?.name,
        };
    }
  };

  const handleMessageSelect = (message: MessageItem) => {
    if (!messageCanSelect) return;
    setCurrentMessageId(message?.messageId as string);
    onMessageSelect?.(message);
  };

  const handleCheckedChange = (checked: boolean) => {
    onCheckedChange?.(message?.messageId as string, checked);
  };

  const handleResize = () => {
    if (wrapperRef?.current && msgRef?.current && operatorShow) {
      const msgWidth = msgRef?.current?.clientWidth;
      const msgHeight = msgRef?.current?.clientHeight;
      const msgWrapperWidth = wrapperRef?.current?.clientWidth;

      if (msgWidth + PROMPT_MESSAGE_OPERATE_WIDTH >= msgWrapperWidth) {
        setHoverClass('bottom');
      } else {
        if (msgHeight > PROMPT_MESSAGE_ONE_LINE_HEIGHT) {
          setHoverClass('bottom-right');
        } else {
          setHoverClass('right');
        }
      }

      setRightPos(msgWrapperWidth - msgWidth);
    }
    // 监听media-list容器的宽度
    if (mediaListRef?.current) {
      const mediaListWidth = mediaListRef?.current?.clientWidth;
      const canContainMaxCardNum = Math.floor(mediaListWidth / 270);
      setCanContainMaxCardNum(canContainMaxCardNum);
    }
  };

  const handleResizeDebounce = useCallback(debounce(handleResize, 300), [handleResize]);

  const handleFileContentRender = () => {
    try {
      const content = message.roleEnum === 'FAKE' ? message?.content : message?.finalContent;
      if (message.roleEnum === 'FAKE') {
        return fileContentRender?.(JSON.parse(content as string), progress);
      }
      const mediaInfo =
        message?.mediaInfo || (message?.mediaInfoJson && JSON.parse(message?.mediaInfoJson));
      return (
        <>
          {fileContentRender?.(JSON.parse(content as string), progress)}
          {mediaInfo && (
            <div className="human-fake-prompt">
              {mediaInfo?.inputContent && (
                <p>Create a Presentation about {mediaInfo?.inputContent}</p>
              )}
              {mediaInfo?.channelAdvanceConfig && (
                <div className="tags">
                  {Object.keys(mediaInfo?.channelAdvanceConfig).map((i) => (
                    <span key={i}>
                      {i === 'page'
                        ? `${mediaInfo?.channelAdvanceConfig?.[i]}Pages`
                        : mediaInfo?.channelAdvanceConfig?.[i]}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      );
    } catch (e) {
      console.error(e);
    }
  };
  const handleTranslateContent = (language: string, content?: string) => {
    onTranslateContent?.(language, content);
    closeOperatePopover();
    sendEvent('SelectParagraph_Translate', {
      channelId: channel?.channelId,
      language,
      selectContent: content,
    });
    sendDAEvent('TextSelectionFeatureButtonClick', {
      current_scene: 'chat_message',
      target_language_choosed: language,
      messageid: message.id,
      drag_content: content,
      doc_id: message.mediaInfo?.mediaId,
      button_name: 'translate',
    });
  };
  const handleCopy = (text: string) => {
    onCopied?.(text);
    closeOperatePopover();
    sendEvent('SelectParagraph_Copy', {
      channelId: channel?.channelId,
      selectContent: text,
    });
    sendDAEvent('TextSelectionFeatureButtonClick', {
      current_scene: 'chat_message',
      target_language_choosed: selectionLanguage.languageEnglish,
      messageid: message.id,
      drag_content: text,
      doc_id: message.mediaInfo?.mediaId,
      button_name: 'copy',
    });
  };

  useUpdateEffect(() => {
    if (isReady) {
      stop();
      onLoaded?.();
    }
  }, [isReady]);

  useUpdateEffect(() => {
    onBaseTimeChange?.(time);
  }, [time]);

  useEffect(() => {
    const observer = new ResizeObserver(handleResizeDebounce);

    if (wrapperRef?.current && type === 'HUMAN') {
      observer.observe(wrapperRef?.current);
    }

    return () => observer.disconnect();
  }, [msgRef?.current, wrapperRef?.current, mediaListRef?.current]);

  useEffect(() => {
    const handleSystemCopy = () => {
      const copiedText = window?.getSelection()?.toString();
      sendEvent('Chat_selectparagraph_copy', {
        channelId: message?.channelId,
        selectContent: copiedText,
        channelType: isUpload ? 'DOC' : 'CHAT',
      });
    };

    const msgContainer = msgRef.current;
    if (msgContainer) {
      msgContainer.addEventListener('copy', handleSystemCopy);

      return () => {
        msgContainer.removeEventListener('copy', handleSystemCopy);
      };
    }
  }, []);

  useEffect(() => {
    if (!message?.processing && messageList?.length > 0) {
      sendEvent('DoubleResponseShow', {
        channel_id: conversation,
        message_id_1: messageList?.[0]?.messageId,
        message_id_2: messageList?.[1]?.messageId,
        type: message?.actionResult,
      });
    }
  }, [message?.processing, messageList]);

  const markdownContentRender = ({
    content,
    quoteInfoList,
    intentName,
  }: {
    content: string;
    quoteInfoList?: QuoteInfoItem[];
    intentName?: string;
  }) => {
    return message?.error ? (
      <Space className="network-error" size={4} align="start">
        <CustomIcon className="network-error-icon" type="error" />
        {message?.content}
      </Space>
    ) : (
      <Suspense>
        <Markdown
          className={classNames({
            'message-content-cursor': message?.cursor,
          })}
          content={content}
          processing={message?.processing}
          onCopied={onCopied}
          messageId={message.messageId}
          quoteInfoList={quoteInfoList}
          cursor={message?.cursor}
          intentName={intentName}
        />
      </Suspense>
    );
  };

  const messageContentRender = (messageItem: MessageItem) => {
    return (
      <>
        {messageItem?.tip ? (
          <SearchTip message={messageItem} text={t('pages.chat.searching')} />
        ) : (
          <div
            className={classNames({
              'message-content': true,
              'message-content-human': type === 'HUMAN' || type === 'FAKE',
              'message-content-human-fake': type == 'HUMAN_FAKE',
              'message-content-ai': type === 'AI',
              'message-content-hover': !message?.processing,
              'message-content-can-select': messageCanSelect,
              'message-content-selected': currentMessageId === messageItem?.messageId,
              'message-content-file': isFunction(fileContentRender),
            })}
            key={messageItem?.messageId}
            onClick={() => handleMessageSelect(messageItem)}
          >
            <div className="message-content-core" ref={msgRef}>
              {isFunction(extraHeaderRender) &&
                extraHeaderRender?.(messageItem, {
                  root: messageList,
                  currentMessageId,
                })}
              {isFunction(extraBeforeContentRender) && extraBeforeContentRender?.(messageItem)}
              {isFunction(fileContentRender) ? (
                handleFileContentRender()
              ) : (
                <>
                  {type === 'HUMAN' && messageItem?.content && (
                    <div
                      className="prompt-message-text"
                      data-isarabic={isArabic(messageItem?.content ?? '')}
                    >
                      {messageItem?.content}
                    </div>
                  )}
                  {type === 'AI' && markdownContentRender(getMessageContentText(messageItem))}
                </>
              )}
              {isFunction(extraAfterContentRender) && extraAfterContentRender?.(messageItem)}
            </div>
            {isFunction(extraFooterRender) &&
              extraFooterRender?.(messageItem, {
                root: messageList,
                currentMessageId,
                chooseLoading,
                onMessageSelect: setCurrentMessageId,
                onMessageChoose: setChooseLoading,
              })}
            {isFunction(extraInnerOperateRender) && extraInnerOperateRender?.(message, {})}
          </div>
        )}
      </>
    );
  };

  const renderMessageMedia = () => {
    if (message.roleEnum !== 'HUMAN' && message.roleEnum !== 'SUMMARYPROMPT') return null;
    if (message?.messageMediaInfo) {
      const mediaCount = message?.messageMediaInfo?.docCardList?.length ?? 0;
      if (mediaCount === 0) return null;
      if (
        message.messageId === 'fake-message-prompt-id' &&
        !message?.fileParsed &&
        mediaCount > 1
      ) {
        return (
          <div className={classNames('message-media-parsing', !message.content && 'empty')}>
            <Spin size="small" />
            Parsing...
          </div>
        );
      }
      return (
        <div
          className={classNames({
            'message-media': true,
            silent: inSharePage,
            'message-check': checkMode,
            'media-as-content': isHideMsgContent,
          })}
        >
          {mediaCount > canContainMaxCardNum && mediaCount > 1 && (
            <div
              className="title"
              onClick={() => {
                setMediaListLaunch(!mediaListLaunch);
              }}
            >
              {mediaCount} files <CustomIcon type={mediaListLaunch ? 'popai-up' : 'popai-down'} />
            </div>
          )}
          <div
            className={classNames({
              content: true,
              launch: mediaListLaunch,
            })}
          >
            <div className="media-list" ref={mediaListRef}>
              {message?.messageMediaInfo?.docCardList?.map((i, index) => (
                <div
                  className="media-list-item"
                  key={i?.md5 || index}
                  onClick={() => {
                    if (inSharePage || i?.err_msg || i?.file_page_count === 0) {
                      return;
                    }
                    onFileCardClick?.(i);
                    sendEvent('Click_File');
                  }}
                >
                  <File
                    silent={inSharePage}
                    content={{
                      file_name: i.file_name,
                      file_type:
                        message.messageId === 'fake-message-prompt-id'
                          ? getFileType(i.file_type)
                          : i.file_type,
                      file_page_count: i?.file_page_count,
                    }}
                    isReady={
                      message.messageId !== 'fake-message-prompt-id' ||
                      (i?.err_msg?.length || 0) > 0
                    }
                    errorMsg={i?.err_msg}
                  />
                  {!i?.err_msg && i?.file_page_count > 0 && (
                    <CustomIcon className="view-media" type="popai-maximize" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  };
  //如果是onechat或者是分享页, 同一个对话中的不同message，AI可能使用不同的模型，根据message.model展示对应头像
  // const messageAvatarInfo = useMemo(() => {
  //   if ((isOneChatChannel || inSharePage) && message.roleEnum === 'AI') {
  //     return {
  //       aiInfo: aiModelAvatar?.[message.model as string],
  //       humanInfo: avatarInfo?.humanInfo,
  //     };
  //   } else {
  //     return avatarInfo;
  //   }
  // }, [message]);

  /**
   * 是否隐藏对话容器
   * one chat，用户上传文档且没有发送消息，隐藏容器，展示文档列表
   *
   */
  const isHideMsgContent = useMemo(() => {
    const isSharePage = window.location.pathname.includes('share');
    if (isOneChatChannel || isSharePage) {
      // one chat
      const hasDocs = (message?.messageMediaInfo?.docCardList?.length ?? 0) > 0; // 上传了文档
      if (type === 'HUMAN' && !message?.content && hasDocs) {
        return true;
      }
    }
    return false;
  }, []);

  return (
    <>
      {messageList?.length > 0 && tipTittle && (
        <div className="choose-response-tip">
          <div className="choose-response-tip-title">{tipTittle}</div>
          <div className="choose-response-tip-description">
            {t('componnet.message.chooseResponseTip')}
          </div>
        </div>
      )}
      <div className="message-container">
        <Space size={12}>
          {checkMode && <Checkbox checked={checked} onChange={handleCheckedChange} />}
          <UserAvatar
            size={32}
            // src={type !== 'AI' ? messageAvatarInfo?.src : null}
            // bordered={!(type === 'AI' || messageAvatarInfo?.src)}
            // info={type === 'AI' ? messageAvatarInfo?.aiInfo : messageAvatarInfo?.humanInfo}
            src={type !== 'AI' ? avatarInfo?.src : null}
            bordered={!(type === 'AI' || avatarInfo?.src)}
            info={type === 'AI' ? avatarInfo?.aiInfo : avatarInfo?.humanInfo}
          />
        </Space>
        <div
          className={classNames({
            'message-content-wrapper': true,
            'message-content-wrapper-human': type === 'HUMAN' || type === 'FAKE',
            'message-content-wrapper-fill': messageList?.length > 0,
            [`message-content-wrapper-${getDevice()}`]: true,
            'message-content-hide': isHideMsgContent,
          })}
          ref={wrapperRef}
        >
          {message?.mediaInfo?.title && message?.mediaInfo?.type && (
            <File
              content={{
                file_name: message.mediaInfo.title,
                file_type: message.mediaInfo.type ? mediaTypeMap[message.mediaInfo.type].type : '',
                file_desc:
                  mediaTypeMap[message?.mediaInfo?.type || 'OUTLINE'].desc === 'Outline'
                    ? t('common.outline')
                    : t('common.presentation'),
              }}
              style={{ marginBottom: '12px' }}
              onClick={() => {
                setPptChatClickMediaType(message?.mediaInfo?.type);
                sendEvent(
                  message?.mediaInfo?.type === EMediaType.SLIDES
                    ? 'ClickSlidesCardSwitchGUI'
                    : 'ClickOutlineCardSwitchGUI',
                );
              }}
            />
          )}
          {messageList?.length > 0 ? (
            <div
              className="sub-messages-container"
              style={{
                gridTemplateColumns:
                  answerDirection === 'horizontal' ? `repeat(${messageList?.length}, 1fr)` : 'none',
              }}
            >
              {messageList?.map((subMessage) => messageContentRender(subMessage))}
            </div>
          ) : (
            messageContentRender(message)
          )}
          {isFunction(extraOperateRender) &&
            (message?.content?.length ?? 0) > 0 &&
            extraOperateRender?.(message, operateOption)}
          {isFunction(extraFeedbackRender) && extraFeedbackRender?.(message)}
          {isFunction(extraBottomRender) &&
            extraBottomRender?.(message, {
              currentMessageId,
              onMessageSelect: setCurrentMessageId,
            })}
        </div>
      </div>
      {isFunction(extraSelectionRender) && extraSelectionRender?.(message, selection)}

      {/* 文件 */}
      {renderMessageMedia()}
      {isNotPPTChannel && type === 'AI' && !location.pathname.includes('share.html') && (
        <SelectionOperationPopover
          currentScene="chat_message"
          ref={selectionOperationRef}
          selection={selection}
          messageId={message.id}
          onTranslateContent={handleTranslateContent}
          onCopy={handleCopy}
          onSaveFinish={closeOperatePopover}
          onSelectionFinished={(text: string) =>
            sendDAEvent('SelectText', {
              current_scene: 'chat_message',
              messageid: message.id,
              drag_content: text,
            })
          }
        />
      )}
      {isFunction(extraSelectionRender) && extraSelectionRender?.(message, selection)}
    </>
  );
});

export default Message;
