import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Popover } from 'antd';
import SelectionOperations from '@/pages/Chat/components/SelectionOperations';
import { useDA } from '@/hooks';
import { useChatStore } from '@/store';

export interface SelectionOperationPopoverRef {
  setOperateOpen?: (open: boolean) => void;
}

interface SelectionOperationPopoverProps {
  /**触发场景，埋点使用 */
  currentScene?: string;
  selection: { text: string; top: number; left: number; height: number; width: number };
  messageId?: number;
  isUpload?: boolean;
  onTranslateContent: (language: string, content?: string) => void;
  onCopy: (text: string) => void;
  onGoogleSearch?: () => void;
  onSelectionFinished?: (text: string) => void;
  onSaveFinish?: () => void;
}

const SelectionOperationPopover = forwardRef<
  SelectionOperationPopoverRef,
  SelectionOperationPopoverProps
>((props, ref) => {
  const {
    selection,
    messageId,
    isUpload,
    onTranslateContent,
    onCopy,
    onGoogleSearch,
    onSelectionFinished,
  } = props;

  const { sendDAEvent } = useDA();

  const { channel } = useChatStore();

  const [operateOpen, setOperateOpen] = useState(false);
  const [selectionText, setSelectionText] = useState('');
  const [isClickingInsidePopover, setIsClickingInsidePopover] = useState(false);

  const handlePopoverClick = () => {
    setIsClickingInsidePopover(false);
  };

  useImperativeHandle(ref, () => ({
    setOperateOpen: (open: boolean) => setOperateOpen(open),
  }));

  useEffect(() => {
    if (selection.text.trim() === '') {
      if (!isClickingInsidePopover) {
        setOperateOpen(false);
      }
    } else {
      setSelectionText(selection.text);
      setOperateOpen(true);
      onSelectionFinished?.(selection.text);
    }
  }, [selection.text, isClickingInsidePopover]);

  useEffect(() => {
    if (operateOpen) {
      sendDAEvent('DocChat_SelectText_Show', { channelid: channel?.channelId });
    }
  }, [operateOpen]);

  return (
    <Popover
      overlayInnerStyle={{
        padding: '4px 0px',
      }}
      content={
        <div onMouseDown={() => setIsClickingInsidePopover(true)} onMouseUp={handlePopoverClick}>
          <SelectionOperations
            currentScene={props?.currentScene}
            selectionText={selectionText}
            messageId={messageId}
            isUpload={isUpload}
            onTranslateContent={onTranslateContent}
            onCopy={onCopy}
            onSaveFinish={() => setOperateOpen(false)}
            onGoogleSearch={onGoogleSearch}
          />
        </div>
      }
      open={operateOpen}
      onOpenChange={(open) => setOperateOpen(open)}
      trigger={['click']}
      arrow={false}
    >
      <span
        style={{
          position: 'fixed',
          top: `${selection.top}px`,
          left: `${selection.left}px`,
          height: `${selection.height}px`,
          width: `${selection.width}px`,
          pointerEvents: 'none',
        }}
      />
    </Popover>
  );
});

export default SelectionOperationPopover;
