import axios from '@/common/request';

interface PPTThemeRes {
  code: number;
  msg: string;
  data: any;
}

export async function addPPTTheme(params: {
  uuid: string;
  name: string;
  cover: string;
  content: string;
}): Promise<PPTThemeRes> {
  const result = await axios.post<PPTThemeRes>('/api/v1/admin/addPPTTemplateConfig', params);
  return result.data;
}

export async function getPPTThemsList() {
  const result = await axios.get('/api/v1/ppt/getPPTTemplateConfigList');
  return result.data;
}

export async function getPPTThemeConfigByUuid(params: { uuid: string }) {
  const result = await axios.get<PPTThemeRes>('/api/v1/ppt/getPPTTemplateConfigByUuid', {
    params,
  });
  return result.data;
}
