import CustomIcon from '@/components/CustomIcon';
import { Editor } from '@tiptap/core';
import classNames from 'classnames';

import { useCallback, useState } from 'react';
import ReactBubbleMenu from '../../components/ReactBubbleMenu';
import { getNodeBySelection } from '../../tools';
import './index.less';
import { useTranslation } from 'react-i18next';

export type ToolbarOperateType = 'longer' | 'shorter' | 'continue';

export interface ToolbarEvent {
  operate: ToolbarOperateType; //指令
  nodeId?: string; //节点id
  selection?: string; //选区文字
  nodeJson?: any; //节点json
  editor?: Editor;
}

interface ContentToolbarProps {
  editor: Editor;
  scale?: number;
  onChange?: (evt: ToolbarEvent) => void;
}

const operations: {
  value: ToolbarOperateType;
  label: string;
  icon: React.ReactNode;
}[] = [
  {
    value: 'longer',
    label: 'Longer',
    icon: <CustomIcon className="toolbar-icon" type="longParagraph" />,
  },
  {
    value: 'shorter',
    label: 'Shorter',
    icon: <CustomIcon className="toolbar-icon" type="shortParagraph" />,
  },
  {
    value: 'continue',
    label: 'Continue',
    icon: <CustomIcon className="toolbar-icon" type="pencil" />,
  },
];

const ContentToolbar: React.FC<ContentToolbarProps> = (props) => {
  const { editor, scale = 1, onChange } = props;

  const [currentButton, setCurrentButton] = useState<number>(-1);
  const { t } = useTranslation();

  const handleChange = useCallback(
    async (value: ToolbarOperateType, index: number) => {
      setCurrentButton(index);

      const { state } = editor;
      const { from, to } = state?.selection;
      const { tr, doc } = state;

      const id: string = `mark_${Date.now()}`;
      const text = state.doc.textBetween(from, to);
      const highlightMark = editor?.schema.marks.highlight.create({
        id,
        className: 'p-enrich-selection',
      });

      tr.removeMark(0, doc.content.size, editor.schema.marks.highlight);
      tr.addMark(from, to, highlightMark);
      editor.view.dispatch(tr);

      onChange?.({
        operate: value,
        selection: text,
        nodeId: id,
        editor,
      });
    },
    [editor],
  );

  return (
    <ReactBubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      scale={scale}
      className="present-toolbar-btn"
      onHide={() => {
        setCurrentButton(-1);
      }}
      shouldShow={({ editor }) => {
        const selectionEmpty = editor.state.selection.empty;

        if (selectionEmpty) {
          return false;
        }
        //跨选区则不显示
        const headNode = getNodeBySelection(editor);
        const anchorNode = getNodeBySelection(editor, true);
        const inOneNode = !!headNode && !!anchorNode && headNode.eq(anchorNode);

        return inOneNode;
      }}
    >
      {operations.map((item, index) => (
        <button
          className={classNames({
            'active-button': currentButton === index,
          })}
          key={index}
          onClick={() => {
            handleChange(item.value, index);
          }}
        >
          {item?.icon}
          {t(`pages.chat.${item.value}`)}
        </button>
      ))}
    </ReactBubbleMenu>
  );
};

export default ContentToolbar;
