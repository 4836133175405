import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  lazy,
  Suspense,
} from 'react';
import classNames from 'classnames';
import { EditorContent, JSONContent, Editor } from '@tiptap/react';
// import Markdown from '@/components/Markdown';
import { useEditor } from './hooks/useEditor';
import {
  getNodeByMarkId,
  getRangeBetweenNodes,
  setContentInRange,
  processBoldContent,
} from './tools';
import { Content_Type, MarkdownContent, PresentationContent, PresentationOptions } from './types';
import ContentToolbar, { ToolbarEvent } from './widgets/ContentToolbar';
import './index.less';

const Markdown = lazy(() => import('@/components/Markdown'));

export interface PresentationViewRef {
  editor?: Editor;
  replaceSelectionText?: (text: string, markId: string) => void;
}
interface PresentationViewProps {
  options?: PresentationOptions;
  className?: string;
}

const PresentationView = forwardRef<PresentationViewRef, PresentationViewProps>((props, ref) => {
  const { options } = props;
  const {
    content,
    activeCard,
    isHover,
    showLabel = false,
    showLogo = true,
    showShadow = false,
    showBorder = false,
    showToolbar = false,
    innerModel = false,
    headingLineBreak = true,
    editable = false,
    contentMarks = [],
    borderRadius = 16,
    maxCardHeight,
    scale = 1,
    cardGap = 30,
    theme = 'light',
    idPre = '',
    container,
    onCardClick,
    onBeforeCreate,
    onContentTypeChange,
    onUpdate,
    onToolbarClick,
    onImageClick,
    onContentOperation,
  } = options ?? {};

  const [showToolbarSelection, setShowToolbarSelection] = useState(false);
  const domRef = useRef<HTMLDivElement | null>(null);

  const editor = useEditor({
    editable,
    idPre,
    showLogo,
    innerModel,
    showLabel,
    headingLineBreak,
    theme,
    onCardClick,
    onImageClick,
    onBeforeCreate,
    onUpdate,
    onContentOperation,
  });

  const handleToolbarClick = (evt: ToolbarEvent) => {
    setShowToolbarSelection(true);
    onToolbarClick?.(evt);
  };

  useImperativeHandle(ref, () => ({
    editor,
    /**
     * 替换文字的逻辑：
     * - 划词时将选中文字用mark包裹，并给这个mark赋一个id。
     * - 替换文字时根据id找到这个mark所在的node，计算出node的from和to，然后替换文字
     * - 因为这个node是text类型的，替换文字后，它的mark会丢失，下次替换找不到，所以替换后再手动把mark给他加上，id要和之前的一样
     * - 有时，用户替换文字后，在mark中间按回车换行，text node就会分裂成2+个node，并且带有相同id的mark，这时就要找到这些mark的最小from和最大to，进行替换。
     */
    replaceSelectionText: (text: string, markId: string) => {
      if (!markId || !text || !editor) {
        return;
      }
      const nodesWithMark = getNodeByMarkId(markId, editor);
      if (!nodesWithMark?.length) {
        return;
      }
      let mark = nodesWithMark[0]?.mark;
      const range = getRangeBetweenNodes(nodesWithMark);
      Promise.resolve().then(() => {
        setContentInRange(editor, range, { text, mark });
        setShowToolbarSelection(false);
        editor
          .chain()
          .setTextSelection({ from: range.from, to: range.from })
          .focus()
          .scrollIntoView()
          .run();
      });
    },
  }));

  useEffect(() => {
    const maxCardHeightStyle = maxCardHeight ? `${maxCardHeight}px` : 'inherit';
    const setProperty = domRef?.current?.style?.setProperty.bind(domRef?.current?.style);
    setProperty?.('--p-card-gap', `${cardGap}px`);
    setProperty?.('--p-card-border-radius', `${borderRadius}px` ?? '');
    setProperty?.('--p-card-max-card-height', maxCardHeightStyle);
    scale && setProperty?.('--p-card-scale', `${scale}`);
  }, [borderRadius, cardGap, maxCardHeight, scale]);

  useEffect(() => {
    if (container) {
      const profix = 'presentation-container-theme';
      const originalClassName = container?.getAttribute('class')?.split(` ${profix}`)?.[0] ?? '';
      container?.setAttribute('class', `${originalClassName} ${profix}-${theme}`);
      container?.setAttribute('presentation-theme', theme);
    }
  }, [container, theme]);

  useEffect(() => {
    let ids: string[] = [];
    if (content?.type === 'doc') {
      ids = (content as JSONContent)?.content?.map((card) => idPre + card?.attrs?.id) ?? [];
    } else if (content?.type === 'markdown') {
      ids = [`${idPre}md-outline`];
    }
    onContentTypeChange?.({
      type: content?.type as Content_Type,
      content: content as PresentationContent,
      ids,
    });
  }, [content?.type]);

  const enableBold = useMemo(() => {
    return contentMarks.includes('bold');
  }, [contentMarks]);

  useEffect(() => {
    // 判断是否处于组合输入状态
    if (editor && !editor.storage.doc.isComposingInput) {
      if (content?.type === 'doc') {
        (content as JSONContent)?.content?.map((card, index) => {
          card.attrs = {
            ...card?.attrs,
            index: index + 1,
          };
        });
        Promise.resolve().then(() => {
          const currentSelection = editor?.state?.selection;
          editor?.commands.setContent(
            (content as JSONContent) ?? { type: 'doc', content: [] },
            false,
          );
          //setContent后，光标会跳到文档末尾，手动重置到之前的位置上
          currentSelection && editor?.chain().setTextSelection(currentSelection).run();
        });
        if (enableBold) {
          processBoldContent(content.content as JSONContent);
        }
      } else if (content?.type === 'markdown') {
        (content as JSONContent).attrs = { id: 'md-outline' };
      }
    }
  }, [content]);

  return (
    <div
      className={classNames({
        'presentation-content': true,
        'show-border': showBorder,
        'show-shadow': showShadow,
        'cursor-pointer': isHover,
        'edit-mode': editable,
        'show-toolbar-selection': showToolbarSelection,
        [`theme-${theme}`]: !!theme,
      })}
      ref={domRef}
    >
      {showBorder ? (
        <style type="text/css">{`
        div#${idPre}${activeCard} .presentation-card-container{
          border: var(--p-card-active-border);
        }
      `}</style>
      ) : undefined}

      {editor && showToolbar && content?.type === 'doc' && (
        // 该div不可删除，否则会报错， ContentToolbar封装的第三方组件直接操作dom导致react在remove child时报错
        <div>
          <ContentToolbar editor={editor} scale={scale} onChange={handleToolbarClick} />
        </div>
      )}
      {content?.type === 'doc' && <EditorContent editor={editor} />}
      {content?.type === 'markdown' && (
        <div
          onClick={(event: React.MouseEvent) => {
            onCardClick?.({
              attrs: { id: 'md-outline', index: 0 },
              view: undefined,
              pos: undefined,
              event: event,
            });
          }}
        >
          <div
            id={`${idPre}${'md-outline'}`}
            className="presentation-markdown-view markdown-layout-outline"
          >
            <Suspense>
              <Markdown content={(content as MarkdownContent)?.content} />
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
});

export default PresentationView;

export * from './types';
