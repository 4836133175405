import ListItem from '@tiptap/extension-list-item';

import { getNodeByPos, getNestingLevel } from '../../tools';

//判断当前listitem是否有子列表（bulletList或orderedList）
const hasList = ($head: any): boolean => {
  const listItemNode = $head.node($head.depth - 1);

  let hasChildList = false;

  listItemNode.forEach((node: any) => {
    if (node?.type?.name === 'bulletList' || node?.type?.name === 'orderedList') {
      hasChildList = true;
    }
  });

  return hasChildList;
};
const MAX_LEVEL = 1;

const PListItem = ListItem.extend({
  name: 'listItem',

  content: 'paragraph block*',

  draggable: false,

  addAttributes() {
    return {
      id: {
        default: undefined,
        rendered: false,
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        const { $head, $from } = editor?.state?.selection;
        const level = getNestingLevel($from);
        const hasChildList = hasList($head);

        //如果当前listitem为第二级li或有第二级li，则不进行缩进操作
        if (level > MAX_LEVEL || hasChildList) {
          return true;
        } else {
          const { from } = editor?.state?.selection;
          const listItemNode = getNodeByPos(from, 'listItem', editor);
          const bulletListNode = getNodeByPos(from, 'bulletList', editor);

          //如果当前listitem为 bulletListNode 中的第一个li，则禁止行缩进操作
          if (bulletListNode?.firstChild === listItemNode) {
            return true;
          }

          return this.editor.commands.sinkListItem(this.name);
        }
      },
      'Shift-Tab': ({ editor }) => {
        const { $from } = editor?.state?.selection;
        const level = getNestingLevel($from);
        if (level > 1) {
          return this.editor.commands.liftListItem(this.name);
        }
        return true;
      },
    };
  },
});

export default PListItem;
