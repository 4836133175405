import { useEditor } from '@tiptap/react';
import ExtensionKit from '@/pages/Chat/components/Write/extensions/extension-kit';

export const useReadOnlyBlockEditor = () => {
  const editor = useEditor(
    {
      autofocus: false,
      editable: false,
      extensions: [...ExtensionKit()],
      editorProps: {
        attributes: {
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
        },
      },
    },
    [],
  );

  return {
    editor,
  };
};
