/*
 * @Author: wubo
 * @Date: 2023-08-22 14:34:51
 * @Description:
 * @LastEditTime: 2023-09-19 16:15:57
 * @LastEditors: wubo
 */

import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  const {
    VITE_APP_META_PIXEL_ID1,
    VITE_APP_META_PIXEL_ID2,
    VITE_APP_META_PIXEL_ID3,
    VITE_APP_META_PIXEL_ID4,
  } = import.meta.env;

  return VITE_APP_META_PIXEL_ID1 &&
    VITE_APP_META_PIXEL_ID2 &&
    VITE_APP_META_PIXEL_ID3 &&
    VITE_APP_META_PIXEL_ID4 ? (
    <Helmet>
      <script id="facebook-pixel-script">
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${VITE_APP_META_PIXEL_ID1});
        fbq('track', 'PageView');
        fbq('init', ${VITE_APP_META_PIXEL_ID2});
        fbq('track', 'PageView');
        fbq('init', ${VITE_APP_META_PIXEL_ID3});
        fbq('track', 'PageView');
        fbq('init', ${VITE_APP_META_PIXEL_ID4});
        fbq('track', 'PageView');`}
      </script>
      <noscript id="facebook-pixel-image">
        {`<img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=${VITE_APP_META_PIXEL_ID1}&ev=PageView&noscript=1"
        />
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=${VITE_APP_META_PIXEL_ID2}&ev=PageView&noscript=1"
        />
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=${VITE_APP_META_PIXEL_ID3}&ev=PageView&noscript=1"
        />
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=${VITE_APP_META_PIXEL_ID4}&ev=PageView&noscript=1"
        />
        `}
      </noscript>
    </Helmet>
  ) : null;
};

export default MetaPixel;
