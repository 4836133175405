import { mergeAttributes } from '@tiptap/core';
import Heading from '@tiptap/extension-heading';

const PHeading = Heading.extend({
  name: 'heading',

  draggable: false,

  addAttributes() {
    return {
      id: {
        default: undefined,
      },
      level: {
        default: 1,
      },
      styleType: '',
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];

    const { headingLineBreak } = this.editor?.state.doc.attrs || {};

    const lineClass = !headingLineBreak ? { class: 'heading-no-break' } : {};

    const { attrs } = node;

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, lineClass, {
        class: `ppt-heading-${level}${attrs.styleType ? attrs.styleType : ''}`,
      }),
      0,
    ];
  },

  //屏蔽井号创建heading的规则
  // addInputRules() {
  //   return [];
  // },
});

export default PHeading;
