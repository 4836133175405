import Document from '@tiptap/extension-document';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';

import {
  getNodeByPos,
  getNodePos,
  selectionCrossNodeContainer,
  getNodeBySelection,
} from '../../tools';
import { HEADING_SIZE, CARD_BLOCK_SIZE } from '../../config';

const Doc = Document.extend({
  name: 'doc',

  content: 'card*',

  draggable: false,

  addStorage() {
    return {
      isComposingInput: false,
    };
  },

  addAttributes() {
    return {
      showLogo: {
        default: false,
      },
      innerModel: {
        default: false,
      },
      showLabel: {
        default: false,
      },
      headingLineBreak: {
        default: true,
      },
      idPre: {
        default: '',
      },
      theme: {
        default: '',
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, _oldState, newState): Transaction | null | undefined => {
          // 检查是否有实质性变化
          if (!transactions.some((tr) => tr.docChanged)) {
            return;
          }
          // 创建一个事务来删除所有空内容的 EmptyNode 节点
          let tr = newState.tr;
          newState.doc.descendants((node, pos) => {
            if (
              newState.doc.content.childCount > 1 &&
              node.type.name === 'card' &&
              node.content.size === 0
            ) {
              tr = tr.delete(pos, pos + node.nodeSize);
            }
          });

          // 如果事务有变化，返回这个事务
          return tr.docChanged ? tr : undefined;
        },
        key: new PluginKey('eventHandler'),
        props: {
          handleTextInput: (_view, _event, text) => {
            const { editor } = this;
            const headingNode = getNodeByPos(text, 'heading', editor);
            const cardBlockNode = getNodeByPos(text, 'cardblock', editor);

            // 选区跨cardblock/cardnode节点时，禁止输入
            if (selectionCrossNodeContainer(editor)) {
              return true;
            }

            const oversize =
              (headingNode && headingNode.textContent?.length > HEADING_SIZE) ||
              (cardBlockNode && cardBlockNode.textContent?.length > CARD_BLOCK_SIZE);

            // 输入内容超长或不在cardblock节点内，则不可输入
            return !cardBlockNode || oversize;
          },

          handleDOMEvents: {
            keydown: (_view, event) => {
              if (event.key === 'Delete') {
                event.preventDefault();
                return true;
              }

              const { editor } = this;
              const { state } = editor;
              const { doc } = state;
              const { from, to, $from, $to } = state?.selection;
              const cardNode = getNodeByPos(from, 'card', editor);
              const cardBlockNode = getNodeByPos(from, 'cardblock', editor);
              const cardNodeNode = getNodeByPos(from, 'cardnode', editor);

              const contentNode = cardNodeNode || cardBlockNode;

              //拦截删除按钮，避免跨cardblock删除
              if (event.key === 'Backspace') {
                if ((cardNode?.textContent?.length ?? 0) < 2 && !contentNode) {
                  event.preventDefault();
                  event.stopPropagation();
                  return true;
                }
                let currentNodePos;
                editor.state.doc.descendants((node, pos) => {
                  if (node === (contentNode as any)) {
                    currentNodePos = pos;
                  }
                });
                if (currentNodePos) {
                  const textBetween = state.doc.textBetween(currentNodePos, from, '\n');

                  //点击删除按钮逐字删除时，若光标在cardblock节点内最前面，则拦截删除事件
                  const deleteToStartOfCardBlock = from === to && textBetween.length <= 1;

                  if (deleteToStartOfCardBlock) {
                    //最后一个字符默认行为删除不掉，需要手动删除
                    const transaction = editor.state.tr.delete(currentNodePos, from);
                    editor.view.dispatch(transaction);
                  }

                  if (deleteToStartOfCardBlock || selectionCrossNodeContainer(editor)) {
                    event.preventDefault();
                    event.stopPropagation();
                    return true;
                  }
                }

                return false;
              }

              if (event.key === 'Enter') {
                // 选区跨cardblock/cardnode节点时，禁止回车
                if (selectionCrossNodeContainer(editor)) {
                  return true;
                }
                const currentHeadingNode = getNodeByPos(from, 'heading', editor);
                const listItemNode = getNodeByPos(from, 'listItem', editor);

                if (currentHeadingNode) {
                  // 在heading中按回车时，根据headingLineBreak决定是否换行
                  //headingLineBreak为 false 时，不换行

                  let dom: any = editor.view.domAtPos(from).node;
                  if (dom instanceof Text) {
                    dom = dom.parentElement;
                  }

                  const isFixed =
                    window?.getComputedStyle?.(dom, null)?.getPropertyValue('position') ===
                    'absolute';

                  let headingLineBreak = doc?.attrs.headingLineBreak;
                  if (!headingLineBreak || isFixed) {
                    event.preventDefault();
                    return true;
                  }

                  //headingLineBreak为 true 时，换行
                  let headingLevel = currentHeadingNode?.attrs.level;

                  //在heading中按回车时，另起一行时创建同级别的heading
                  if (headingLevel && !$to.nodeAfter) {
                    editor?.commands.insertContent({
                      type: 'heading',
                      attrs: { level: headingLevel },
                      consnt: [
                        {
                          type: 'hardBreak', // 使用 Tiptap 的换行类型
                          attrs: { class: 'ProseMirror-trailingBreak' },
                        },
                      ],
                    });
                    event.preventDefault();
                    return true;
                  } else if ($from.pos === $from.start()) {
                    //避免在heading最左侧中按回车时，上一行会变成pargraph
                    editor?.chain().enter().run();
                    event.preventDefault();
                    return true;
                  }

                  return false;
                } else if (listItemNode) {
                  //重写listitem中的回车事件如果当前listitem为空，则不创建新行
                  event.preventDefault();
                  return !!listItemNode?.textContent
                    ? this.editor.commands.splitListItem('listItem')
                    : true;
                } else {
                  const paragraphNode = getNodeByPos(from, 'paragraph', editor);
                  //在cardblock>p中按回车时，如果当前p为空，则不创建新p
                  if (!paragraphNode?.textContent) {
                    event.preventDefault();
                    return true;
                  }
                }
              }
              return false;
            },
            //拦截paste事件
            paste: (_view, event) => {
              //拦截paste事件，手动控制paste逻辑
              event.preventDefault();
              event.stopPropagation();

              let text = event.clipboardData?.getData('text/plain'); // 获取纯文本

              if (!text) {
                return true;
              }

              const { editor } = this;
              const { doc } = editor.state;
              const { from, to } = editor.state.selection;
              const startNode = getNodeByPos(from, 'cardblock', editor);
              const endNode = getNodeByPos(to, 'cardblock', editor);

              //屏蔽跨cardblock的粘贴
              if (endNode && !startNode?.eq(endNode)) {
                return true;
              }

              const headingNode = getNodeByPos(from, 'heading', editor);
              const cardBlockNode = startNode;
              const selecttedLength = to - from;

              //限制heading内容长度
              if (headingNode) {
                //headingLineBreak为true时，粘贴内容不换行
                if (!doc.attrs.headingLineBreak) {
                  text = text.replace(/\n/g, '');
                }
                const headingOverSize =
                  headingNode.textContent.length - selecttedLength + text.length - HEADING_SIZE;

                if (headingOverSize > 0) {
                  text = text.slice(0, Math.max(text.length - headingOverSize, 0));
                }
              }

              //限制cardblock内容长度
              if (cardBlockNode) {
                const cardBlockOverSize =
                  cardBlockNode.textContent.length -
                  selecttedLength +
                  text.length -
                  CARD_BLOCK_SIZE;

                if (cardBlockOverSize > 0) {
                  text = text.slice(0, Math.max(text.length - cardBlockOverSize, 0));
                }
              } else {
                //防止粘贴到cardblock节点外，导致内容混乱
                return true;
              }

              text &&
                editor?.commands.insertContent({
                  type: 'text',
                  text: text,
                });
              return true;
            },
            compositionstart: (_view) => {
              this.storage.isComposingInput = true;
            },
            compositionend: () => {
              this.storage.isComposingInput = false;
            },
          },
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      //mac中是光标移动到最前，window上是选中全文
      'Ctrl-a': () => {
        return false;
      },

      'Mod-a': ({ editor }) => {
        // 获取当前光标位置的节点，若有选区，则取选区的中点

        const { head } = editor?.state?.selection;
        const pargraphNode = getNodeByPos(head, 'paragraph', editor);
        const headhNode = getNodeByPos(head, 'heading', editor);
        const currentNode = pargraphNode || headhNode || getNodeBySelection(editor);
        const currentNodePos = getNodePos(currentNode, editor);

        editor
          .chain()
          .setTextSelection({
            from: currentNodePos + 1, //排除node from的占位
            to: currentNodePos + 1 + (currentNode?.nodeSize ?? 0) - 2, //排除node to的占位
          })
          .run();
        return true;
      },
    };
  },
});

export default Doc;
