import { useState, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import localesResource from '@/locales';
import { useCommonStore, useLanguageStore } from '@/store';
import i18next from '@/i18n';
import { AUTO_OUTPUT_LANGUAGE, AB_EXPERIMENTS } from '@/common/config';
import { lngCodes } from '@/i18n/config';
import { useSundry } from '@/hooks';
import antdLocale from 'antd/locale/en_US';

export type LangType = 'zh' | 'en' | 'ar';
export interface LangConf {
  key: string;
  value: string;
  calibration: string;
  locale: string;
}
export interface KvObj {
  [key: string]: KvObj | string;
}

const getLangByUrl = () => {
  // subpath
  const paths = window.location.pathname.split('/').filter(Boolean);
  const urlLang = paths[0];
  if (lngCodes.includes(urlLang)) return urlLang;
};

// 处理国际化相关功能
const useI18n = () => {
  const { pageLanguage, setPageLanguage, setIsRTLDir, outputLanguage, setOutputLanguage } =
    useCommonStore();
  const {
    langCached, // 判断是否有过缓存
    langTypeList, // 服务数据，支持的语言列表
    langResource, // 缓存 i18n 字符串详情
    setLangCached,
    setLangResource,
    setLangTypeList,
  } = useLanguageStore();
  const { experiment } = useSundry();
  const autoChangeES = experiment(AB_EXPERIMENTS.I18N_AUTOCHANGE_ES);
  const autoESRef = useRef(false);

  const [antdLib, setAntdLib] = useState<any>(antdLocale);

  // 更新router basename，已经有语言 subpath 保留，没有添加（en 保持和用户输入一致）
  const basename = useMemo(() => {
    const pathname = window.location.pathname;
    const isSharePage = pathname.startsWith('/share');
    if (isSharePage) {
      return '';
    }
    const hasBasename = pathname.startsWith(`/${pageLanguage}`);
    const isKeep = pageLanguage === 'en';
    const urlLang = getLangByUrl() as LangType;
    if (hasBasename) {
      return pageLanguage;
    }
    if (urlLang) {
      return urlLang;
    }
    return isKeep ? '' : pageLanguage;
  }, [pageLanguage]);

  // 动态加载第三方库的国际化资源
  const loadLibsLocales = async (lang: LangType) => {
    const libs = localesResource[lang];
    if (!libs) {
      return;
    }
    const { antdLocale, dayjsLocale } = libs;
    const [antd] = await Promise.all([antdLocale.resource(), dayjsLocale.resource()]);
    dayjs.locale(dayjsLocale.key);
    setAntdLib(antd);
  };

  // 根据语言设置文字方向
  const setStyle = (language: string) => {
    document.body.lang = language;
    const rtlLangs = ['ar'];
    if (rtlLangs.includes(language)) {
      setIsRTLDir(true);
      document.body.dir = 'rtl';
      import('@/index.rtl.less');
    } else {
      setIsRTLDir(false);
      document.body.dir = 'ltr';
      if (!outputLanguage?.langcode) {
        setOutputLanguage(AUTO_OUTPUT_LANGUAGE);
      }
    }
  };

  // 更改 i18next 配置的语言和资源
  const changeI18next = async (langType: string, langs: KvObj) => {
    i18next.changeLanguage(langType);
    if (langs) {
      i18next.addResourceBundle(langType, 'translation', langs, true, true);
    }
  };
  const fetchI18nResource = async (langType: LangType) => {
    const isEN = langType === 'en'; // en 在代码中打包 不使用服务数据
    if (langType && !isEN) {
      const langs = await setLangResource(langType);
      if (langs) {
        changeI18next(langType, langs);
      }
    }
  };

  // 除了en, 需要更新 url subpath
  const changeRoutePath = (langType: LangType) => {
    let pathname = window.location.pathname;
    // share.html 是另一个页面，不需要更新 url
    const isSharePage = pathname.startsWith('/share');
    if (isSharePage) {
      return;
    }
    const urlLang = getLangByUrl() as LangType;
    if (urlLang && urlLang !== langType) {
      pathname = pathname.replace(`/${urlLang}`, '');
    }
    const hasBasename = window.location.pathname.startsWith(`/${langType}`);
    const isEn = langType === 'en';
    if (isEn && urlLang) {
      window.history.replaceState({}, '', `${pathname}`);
    }
    if (!isEn && !hasBasename) {
      const { search } = window.location;
      window.location.replace(`/${langType}${pathname}${search}`);
    }
  };

  const updateLangs = async (lang: LangType) => {
    setStyle(lang); // 设置样式 文字方向
    loadLibsLocales(lang); // 设置 libs 的国际化依赖项
    setPageLanguage(lang); // 设置全局的语言字符串
    changeI18next(lang, langResource[lang]); // 更新 i18next 语言 & 资源
  };

  useEffect(() => {
    const urlLang = getLangByUrl() as LangType;
    const urlInvalid = urlLang && !langTypeList.includes(urlLang);
    if (!langTypeList.length || (urlInvalid && pageLanguage === 'en')) {
      return;
    }
    // 命中自动切换西语
    if (autoESRef.current) {
      return;
    }
    const langFrom = [urlLang, pageLanguage].filter(Boolean) as LangType[];

    const lang = langFrom.find((lang) => langTypeList.includes(lang)) || 'en';
    if (pageLanguage !== 'en') {
      setLangCached(); // 设置已缓存过标识
    }
    // 优先用缓存更新 ui 语言 减少等待时间
    updateLangs(lang);
    // 同时从服务获取最新的语言资源更新 ui
    fetchI18nResource(lang);
    // route path 增加语言路径
    changeRoutePath(lang);
  }, [langTypeList]);

  useEffect(() => {
    // 支持的语言列表
    setLangTypeList();
  }, []);

  useEffect(() => {
    const ES_KEY = 'es' as LangType;
    const newUser = pageLanguage === 'en' && !langCached;
    if (autoChangeES && newUser) {
      autoESRef.current = true;
      setLangCached(); // 设置已缓存过标识
      updateLangs(ES_KEY);
      fetchI18nResource(ES_KEY);
      changeRoutePath(ES_KEY);
    }
  }, [autoChangeES]);
  // antd 在外部 provider 中更新国际化资源 & router 在外部更新 basename
  return {
    antdLocale: antdLib,
    locale: basename,
  };
};

export default useI18n;
