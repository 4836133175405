import { Helmet } from 'react-helmet';

const LinkedInAd = () => {
  const { VITE_APP_LINKEDIN_ID } = import.meta.env;

  return VITE_APP_LINKEDIN_ID ? (
    <Helmet>
      <script>
        {`_linkedin_partner_id = "${VITE_APP_LINKEDIN_ID}";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
      </script>
      <script>
        {`(function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </script>
      <noscript>
        {`<img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=${VITE_APP_LINKEDIN_ID}&fmt=gif"
        />`}
      </noscript>
    </Helmet>
  ) : null;
};

export default LinkedInAd;
