import { Editor, JSONContent } from '@tiptap/core';

import { Node, Mark } from '@tiptap/pm/model';

/**
 *
 * @param pos
 * @param typeName
 * @param editor
 * @description 获取光标位置的某类型节点
 * @returns
 */
export const getNodeByPos = (
  pos: number,
  typeName: string,
  editor: Editor | null,
): Node | undefined => {
  if (editor) {
    const { doc } = editor?.state;
    const resolvedPos = doc.resolve(pos);
    for (let depth = resolvedPos.depth; depth > 0; depth--) {
      const node = resolvedPos.node(depth);
      if (node.type.name === typeName) {
        return node;
      }
    }
  }
  return;
};

/**
 *
 * @param $from
 * @description 获取当前listitem所处层级，1为ul下面的第一级（ul-li），2为第二级（ul-li-ul-li）
 * @returns
 */
export const getNestingLevel = ($from: any): number => {
  let level = 0;
  for (let i = $from.depth; i > 0; i--) {
    if ($from.node(i).type.name === 'listItem') {
      level++;
    }
  }
  return level;
};

/**
 *
 * @param attrs
 * @param typeName
 * @param editor
 * @returns
 * @description 设置节点属性，设置同类型所有节点的属性
 */
export const updateNodeAttrs = (
  attrs: Record<string, any>,
  typeName: string,
  editor: Editor | null,
): boolean => {
  if (editor) {
    const { doc, tr } = editor.state;

    doc.descendants((node, pos) => {
      if (node.type.name === typeName) {
        const newAttrs = { ...node.attrs, ...attrs };
        tr.setNodeMarkup(pos, undefined, newAttrs);
      }
    });
    if (tr.steps.length > 0) {
      editor.view.dispatch(tr);
      return true;
    }
    return false;
  }
  return false;
};

/**
 *
 * @param typeName
 * @param editor
 * @returns
 */
export const getNodes = (typeName: string, editor: Editor | null): Node[] => {
  const nodes: Node[] = [];
  if (editor) {
    const { doc } = editor?.state;

    doc.descendants((node, _pos) => {
      if (node.type.name === typeName) {
        nodes.push(node);
      }
    });
  }
  return nodes;
};
/**
 *
 * @param node
 * @param childName
 * @returns 是否包含name为childName的子节点
 */
export const hasChildNode = (node: Node | undefined, childName: string): boolean => {
  let has = false;
  node?.descendants((childNode) => {
    if (childNode.type.name === childName) {
      has = true;
    }
  });

  return has;
};

/**
 *
 * @param editor
 * @description 判断选区是否跨cardblock/cardnode节点
 * @returns
 */
export const selectionCrossNodeContainer = (editor: Editor | null): boolean => {
  if (!editor) {
    return false;
  }

  const { from, to } = editor?.state?.selection;

  const startCardNodeNode = getNodeByPos(from, 'cardnode', editor);
  const endCardNodeNode = getNodeByPos(to, 'cardnode', editor);

  let startNode;
  let endNode;
  if (startCardNodeNode && endCardNodeNode) {
    startNode = startCardNodeNode;
    endNode = endCardNodeNode;
  } else {
    startNode = getNodeByPos(from, 'cardblock', editor);
    endNode = getNodeByPos(to, 'cardblock', editor);
  }

  // 选区跨cardblock节点时，禁止输入
  return (startNode && endNode && !startNode?.eq(endNode)) ?? false;
};

/**
 *
 * @param editor
 * @description
 * 获取当前选区节点，若跨node，则获取选区最后位置（selection.head）所在的node.
 * 若atAnchor===true则获取选区最前位置（selection.anchor ）所在的node。
 * @returns
 */
export const getNodeBySelection = (editor: Editor | null, atAnchor?: boolean): Node | undefined => {
  if (!editor) {
    return;
  }

  const { head, anchor } = editor?.state?.selection;
  const pos = atAnchor ? anchor : head;

  const headingNode = getNodeByPos(pos, 'heading', editor);
  const paragraphNode = getNodeByPos(pos, 'paragraph', editor);
  const listItemNode = getNodeByPos(pos, 'listItem', editor);

  return headingNode || paragraphNode || listItemNode;
};

/**
 *
 * @param currentNode
 * @param editor
 * @description 获取当前节点在文档中的位置
 * @returns
 */
export const getNodePos = (currentNode: Node | undefined, editor: Editor | null): number => {
  if (!editor) {
    return -1;
  }

  const { from, to } = editor?.state?.selection;

  let currentNodePos;
  editor.state.doc.descendants((node, pos) => {
    if (node === (currentNode as any)) {
      currentNodePos = pos;
    }
  });

  return currentNodePos ?? ~~(from + to) / 2;
};

/**
 *
 * @param node
 * @param attrs
 * @param editor
 * @description 设置node的attrs属性
 * @returns
 */
export const setNodeAttrs = (options: {
  node: Node | undefined;
  attrs: Record<string, any>;
  editor: Editor | null;
}) => {
  const { node, attrs, editor } = options;
  if (!editor) {
    return;
  }

  const { doc, tr } = editor.state;

  doc.descendants((_node, pos) => {
    // 这个地方 eq 实现方式需要研究一下，因为传入组件的 node 可能和文档里的 node 不同，导致对比失败
    if ((node && _node.eq(node)) || _node.attrs.id === node?.attrs.id) {
      const newAttrs = { ...node?.attrs, ...attrs };
      tr.setNodeMarkup(pos, undefined, newAttrs);
    }
  });
  if (tr.steps.length > 0) {
    editor.view.dispatch(tr);
    return true;
  }
  return false;

  // const nodePos = getNodePos(node, editor);
  // const transaction = state.tr.setNodeMarkup(nodePos, undefined, { ...node?.attrs, ...attrs });
  // const newState = state.apply(transaction);
  // view.updateState(newState);
};

export interface NodeWithInfo {
  node?: Node;
  mark?: Mark;
  from?: number;
  to?: number;
}

/**
 *
 * @param markId
 * @param editor
 * @description 根据markId获取节点
 * @returns
 */
export const getNodeByMarkId = (markId: string, editor: Editor | null): NodeWithInfo[] => {
  const nodesWithMark: NodeWithInfo[] = [];

  if (editor && markId) {
    editor.state.doc.descendants((node, pos) => {
      node.marks.forEach((mark) => {
        if (mark.attrs.id === markId) {
          nodesWithMark.push({
            node,
            mark,
            from: pos,
            to: pos + node.nodeSize, //node?.text?.length
          });
        }
      });
    });
  }

  return nodesWithMark;
};

/**
 *
 * @param editor
 * @description 获取节点的 from to 信息
 * @returns
 */
export const getNodeWithInfo = (
  currentNode: Node | JSONContent,
  editor: Editor | null,
): NodeWithInfo[] => {
  const nodesWithMark: NodeWithInfo[] = [];

  if (currentNode && editor) {
    editor.state.doc.descendants((node, pos) => {
      if (node === currentNode) {
        nodesWithMark.push({
          node,
          from: pos,
          to: pos + node.nodeSize,
        });
      }
    });
  }

  return nodesWithMark;
};

/**
 *
 * @param nodeInfos
 * @description 获取节点的范围(多个node总的from和to)
 * @returns
 */
export const getRangeBetweenNodes = (nodeInfos: NodeWithInfo[]): { from: number; to: number } => {
  let from = Infinity;
  let to = -1;

  nodeInfos.forEach((item) => {
    from = Math.min(from, item?.from ?? 0);
    to = Math.max(to, item?.to ?? 0);
  });
  return { from, to };
};

export const setContentInRange = (
  editor: Editor,
  range: { from: number; to: number },
  options: {
    text?: string;
    mark?: Mark;
  },
) => {
  const { text = '', mark } = options;
  const { from, to } = range;

  if (from && to) {
    const { tr } = editor.state;
    tr.insertText(text, from, to);
    mark &&
      tr.addMark(
        from,
        from + text.length,
        editor?.schema.marks.highlight.create({
          id: mark?.attrs.id,
          className: mark?.attrs.className,
        }),
      );
    editor.view.dispatch(tr);
  }
};

/**
 *
 * @param editor
 * @param attrs
 * @description 设置文档的attrs
 */
export const setDocAttrs = (editor: Editor, attrs: { [key: string]: any } = {}) => {
  const { tr } = editor?.state;
  Object.keys(attrs).forEach((key) => {
    tr.setDocAttribute(key, attrs[key]);
  });
  editor?.view.dispatch(tr);
};

/**
 * 匹配JSON中的 ** ，匹配到之后加入加粗显示的 Mark
 * @param content
 */
export const processBoldContent = (content: JSONContent) => {
  if (!content || !Array.isArray(content)) {
    return;
  }

  for (let i = 0; i < content.length; i++) {
    const node = content[i];

    // 如果节点类型是 text，且包含 '**'，则匹配出需要加粗的文本
    if (node.type === 'text' && node.text.includes('**')) {
      const parts = node.text.split(/\*\*(.+?)\*\*/);
      const newNodes: {
        type: string;
        text: string;
        marks?: { type: 'bold' }[];
      }[] = [];

      parts.forEach((part: string, index: number) => {
        if (index % 2 === 0 && part) {
          // 偶数部分是普通文本
          newNodes.push({ type: 'text', text: part });
        } else if (part) {
          // 奇数部分是加粗文本
          newNodes.push({
            type: 'text',
            text: part,
            marks: [{ type: 'bold' }],
          });
        }
      });

      // 用处理过的节点数组替换当前节点
      content.splice(i, 1, ...newNodes);
      i += newNodes.length - 1; // 调整索引
    } else if (node.content && Array.isArray(node.content)) {
      processBoldContent(node.content);
    }
  }
};

//根据属性查找node
export const findNodesWithAttr = (node: JSONContent, attr: string, value: any) => {
  let nodesWithAttr: JSONContent[] = [];

  function recurse(node: JSONContent) {
    // 检查当前节点是否含有目标属性且值匹配
    if (node.attrs && node.attrs[attr] === value) {
      nodesWithAttr.push(node);
    }
    // 如果当前节点有子节点，递归遍历它们
    if (node.content) {
      node.content.forEach(recurse);
    }
  }

  recurse(node); // 从文档的根节点开始递归遍历
  return nodesWithAttr; // 返回找到的所有匹配节点
};

//根据 type 中的信息查找node
export const findNodesWithType = (node: JSONContent, typeName: string, typeValue: string) => {
  let nodesWithType: JSONContent[] = [];

  function recurse(node: JSONContent) {
    // 检查当前节点是否含有目标属性且值匹配
    if (node.type && node.type[typeName as any] === typeValue) {
      nodesWithType.push(node);
    }
    // 如果当前节点有子节点，递归遍历它们
    if (node.content) {
      node.content.forEach(recurse);
    }
  }

  recurse(node); // 从文档的根节点开始递归遍历
  return nodesWithType; // 返回找到的所有匹配节点
};

// 设置文本高亮
// from to 是文本的高亮范围，可以通过 getNodeWithInfo 来获取
export const setTextHighlight = (
  editor: Editor,
  range: { from: number; to: number },
  color: string = '#0059FF33',
) => {
  const { from, to } = range;

  editor
    .chain()
    .command(({ tr, dispatch }) => {
      if (dispatch) {
        tr.addMark(from, to, editor.schema.marks.highlight.create({ color }));
        return true;
      }
      return false;
    })
    .run();
};

export const removeHightlight = (editor: Editor, range: { from: number; to: number }) => {
  const { from, to } = range;
  editor
    .chain()
    .command(({ tr, dispatch }) => {
      if (dispatch) {
        tr.removeMark(from, to);
        return true;
      }
      return false;
    })
    .run();
};

//根据attrs中的id 查找node
export const findNodeByID = (node?: JSONContent, id?: string) => {
  if (!node || !id) {
    return undefined;
  }
  return findNodesWithAttr(node, 'id', id)?.[0];
};
// 获取 image file 文件中的图片尺寸信息
export const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = event?.target?.result as string;
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};
// 根据图片链接获取图片的大小
export const getImageSize = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'HEAD', // 发送 HEAD 请求
    });
    const contentLength = response.headers.get('content-length');
    return contentLength ? parseInt(contentLength, 10) : null;
  } catch (error) {
    console.error('Error fetching image size:', error);
    return null;
  }
};
// 根据图片链接获取图片尺寸
export const getImageDimensionsFromURL = async (
  url: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    img.src = url;
  });
};

export const addQueryParam = (url: string, params: any) => {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(params);
  searchParams.forEach((value, key) => {
    urlObj.searchParams.append(key, value);
  });
  return urlObj.toString();
};
