import { forwardRef, useImperativeHandle, useRef } from 'react';
import { STORE_KEY } from '@/common/config';
import { calculateHash, splitFile } from '@/common/utils';
import ToastContent from '@/components/ToastContent';
import { getPresignedUsingGET } from '@/services';
import { useChatStore, useCommonStore, useUserStore } from '@/store';
import { App, Upload } from 'antd';
import axios from '@/common/request';
import { useGASendEvent } from '@/hooks';
import { useTranslation } from 'react-i18next';

import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import type { ResponseType, PresignedData } from '@/type';
import type { AxiosRequestConfig } from 'axios';
import { useGlobalModal } from '@/layout/BasicLayout';
import { gpt4oName } from '@/common/model';

export interface ImageUploadRef {
  uploadMethod?: (params: { file?: File }) => Promise<any>;
  uploadAbort?: () => void;
}

export interface FinishUploadParams {
  imageUrls?: string[];
  model?: string;
  name?: string;
}

interface ImageUploadProps {
  className?: string;
  children?: React.ReactNode;
  onStartUpload?: () => void;
  onFinishUpload?: (params?: FinishUploadParams) => void;
  /**是否禁用 */
  disabled?: boolean;
  type?: 'normal' | 'ppt'; // 上传图片的类型，默认为普通图片上传
  isUploadImage?: boolean;
  beforeUpload?: (params: RcFile) => void; // 上传前的回调
}

const { VITE_APP_UPLOAD_IMAGE_BUCKET } = import.meta.env;

const ImageUpload = forwardRef<ImageUploadRef, ImageUploadProps>((props, ref) => {
  const {
    className,
    children,
    onStartUpload,
    onFinishUpload,
    beforeUpload,
    type = '',
    disabled = false,
  } = props;

  const { message } = App.useApp();
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { userInfo } = useUserStore();
  const { channel } = useChatStore();
  const { chatModel } = useCommonStore();
  const { checkLoginStatus } = useGlobalModal();

  const uploadImgCurl = useRef<AbortController | null>(null);

  const genPrefix = (type: string) => {
    if (type === 'ppt') {
      return `pptProjectImg/${userInfo.uid}/${channel.channelId}`;
    } else {
      return '';
    }
  };

  const uploadAbort = () => {
    uploadImgCurl.current?.abort();
    uploadImgCurl.current = null;
  };

  const uploadMethod = async (option: any) => {
    onStartUpload?.();
    const chunkList = splitFile(option.file);
    const md5 = await calculateHash(chunkList);
    if (md5 && uploadImgCurl.current) {
      let presignedPostUrl: PresignedData;
      // 获取s3上传地址
      try {
        const res = await getPresignedUsingGET<ResponseType<PresignedData>>({
          md5: md5 as string,
          bucket: VITE_APP_UPLOAD_IMAGE_BUCKET,
          prefix: genPrefix(type),
        });
        presignedPostUrl = res.data;
        // 上传文件到s3
        if (presignedPostUrl) {
          const uploadS3FormData = new FormData();
          Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
            uploadS3FormData.append(k, v);
          });
          uploadS3FormData.append('file', option.file);
          const config: AxiosRequestConfig = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            noAuthHeader: true,
            noAlertError: true,
          };
          if (uploadImgCurl.current) {
            config.signal = uploadImgCurl.current.signal;
          }
          await axios.post(presignedPostUrl.url, uploadS3FormData, config);
          if (uploadImgCurl.current) {
            onFinishUpload?.({
              imageUrls: [`${presignedPostUrl.url}${presignedPostUrl.fields.key}`],
              model: chatModel,
              name: option.file?.name,
            });
          }
          sendEvent('Selectphoto');
        }
      } catch (err: any) {
        onFinishUpload?.();
        message.open({
          content: <ToastContent icon="error" content={t('components.ImageUpload.uploadError')} />,
        });
      }
    }
  };

  const handleFileSelect = () => {
    checkLoginStatus?.({ type, model: gpt4oName });
  };

  useImperativeHandle(ref, () => ({
    uploadMethod: async (params) => {
      uploadImgCurl.current = new AbortController();
      await uploadMethod(params);
    },
    uploadAbort,
  }));

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.png,.jpg,.jpeg',
    disabled,
    multiple: false,
    headers: {
      Authorization: localStorage.getItem(STORE_KEY.Authorization) as string,
    },
    beforeUpload: (file: RcFile) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.open({
          content: (
            <ToastContent icon="error" content={t('components.ImageUpload.uploadImageLimit')} />
          ),
        });
      }
      beforeUpload && beforeUpload(file);
      return isJpgOrPng || Upload.LIST_IGNORE;
    },
    showUploadList: false,
    openFileDialogOnClick: !!userInfo?.isLogin,
    customRequest: async (option: any) => {
      uploadImgCurl.current = new AbortController();
      uploadMethod(option);
    },
  };

  return (
    <Upload {...uploadProps} className={className}>
      <div onClick={handleFileSelect}>{children}</div>
    </Upload>
  );
});

export default ImageUpload;
