import { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu';
import React, { useEffect, useState } from 'react';
import { useCurrentEditor } from '@tiptap/react';
import { PBubbleMenuPlugin } from './BubbleMenuPlugin';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type BubbleMenuProps = Omit<
  Optional<BubbleMenuPluginProps, 'pluginKey' | 'editor'>,
  'element'
> & {
  className?: string;
  children: React.ReactNode;
  updateDelay?: number;
  scale?: number;
  onShow?: () => void;
  onHide?: () => void;
};

const ReactBubbleMenu = (props: BubbleMenuProps) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { editor: currentEditor } = useCurrentEditor();

  useEffect(() => {
    if (!element) {
      return;
    }

    if (props.editor?.isDestroyed || currentEditor?.isDestroyed) {
      return;
    }

    const {
      pluginKey = 'bubbleMenu',
      editor,
      tippyOptions = {},
      updateDelay,
      shouldShow = null,
      scale = 1,
      onShow,
      onHide,
    } = props;

    const menuEditor = editor || currentEditor;

    if (!menuEditor) {
      console.warn(
        'BubbleMenu component is not rendered inside of an editor component or does not have editor prop.',
      );
      return;
    }

    const plugin = PBubbleMenuPlugin({
      updateDelay,
      editor: menuEditor,
      element,
      pluginKey,
      shouldShow,
      tippyOptions,
      scale,
      onShow,
      onHide,
      containerLeft: editor?.view.dom.getBoundingClientRect().left,
    });

    menuEditor.registerPlugin(plugin);
    return () => menuEditor.unregisterPlugin(pluginKey);
  }, [props.editor, currentEditor, element, props?.scale]);

  return (
    <div ref={setElement} className={props.className} style={{ visibility: 'hidden' }}>
      {props.children}
    </div>
  );
};

export default ReactBubbleMenu;
