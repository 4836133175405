import { useState, useEffect, useRef } from 'react';

interface Options {
  time?: number;
  timeout?: number;
  timeConstant?: number;
  autoStart?: boolean;
}

interface TimeRef {
  time: number;
  progress: number;
  timeout: number;
  timeConstant: number;
  autoStart: boolean;
}

const initialOptions = {
  time: 0,
  progress: 0,
  timeout: 100,
  timeConstant: 100000,
  autoStart: false,
};

function truncate(num: number, precision: number) {
  return parseFloat((Math.floor(num * 100) / 100).toFixed(precision));
}

const useProgress = (options: Options = {}) => {
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState(0);

  const timerRef = useRef<number>();
  const timeRef = useRef<TimeRef>({
    ...initialOptions,
    ...options,
  });

  useEffect(() => {
    if (timeRef.current.autoStart) {
      start();
    }

    return () => stop();
  }, []);

  const start = (baseTime?: number) => {
    const current = timeRef.current;

    if (baseTime) {
      current.time = baseTime;
    }

    timerRef.current = window.setInterval(() => {
      current.time += current.timeout;
      current.progress = 1 - Math.exp((-1 * current.time) / current.timeConstant);
      setTime(current.time);
      setProgress(truncate(current.progress * 100, 1));
    }, current.timeout);
  };

  const stop = () => {
    clearInterval(timerRef.current);
    timeRef.current.progress = 1;
    timeRef.current.time = 0;
    timerRef.current = undefined;
    setProgress(100);

    setTimeout(() => setProgress(0), 500);
  };

  return { progress, time, start, stop };
};

export default useProgress;
